.quickly-add-goods-modal {
  &__container {
    max-height: 260px;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__table {
    & .ant-table {
      &-placeholder {
        & .ant-table-cell {
          color: $color-independence;
        }
      }

      & .ant-table-row {
        cursor: pointer;
        
        &.selected {
          background: $color-lavender;

          &:hover {
            & .ant-table-cell {
              background: $color-lavender !important;
            }
          }
        }
      }
    }

    &-item {
      &-codes {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &-sellerCode {
        color: $color-metallic-silver;
      }

      &-vendorCode {
        color: $color-islamic-green;
      }

      &-name {
        word-break: break-word;
      }
    }
  }
}
