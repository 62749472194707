.add-address-modal {
  &__title {
    margin-bottom: 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}