.subdivision-users {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__search-container {
    display: flex;
    gap: 10px;
  }

  &__table {
    &_item {
      &-user-email {
        font-size: $fontSize-xsm;
        color: $color-majorelle-blue;
      }
    }
  }
}