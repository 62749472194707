.select-workspace-modal {
  &__table {
    & table {
      border-spacing: 0 6px;
    }

    & .ant-table-cell {
      padding: 0 !important;
      border: none !important;
    }

    &_item {
      cursor: pointer;

      & * {
        font-family: $fontFamily-Roboto-Light;
      }

      &-cell {
        &-name {
          position: relative;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
      }

      &-name-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        display: none;
        align-items: center;
        background-color: $color-lavender;
        background-image: url(../../images/success.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        & .ant-table-cell {
          background: $color-ghost-white;
        }
      }

      &.selected {
        & .ant-table-cell {
          background: $color-lavender;
        }

        &:hover {
          & .ant-table-cell {
            background: $color-lavender !important;
          }
        }

        & .select-workspace-modal__table_item-name-icon {
          display: flex;
        }
      }
    }
  }
}