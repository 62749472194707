.btn {
  padding: 4px 12px;

  & .ant-wave {
    --wave-color: #4945c4 !important;
  }

  &-default {
    border-color: rgba(70, 79, 96, 0.16);
    color: $color-independence;

    &:hover {
      border-color: rgba(70, 79, 96, 0.32) !important;
      color: $color-black !important;
    }

    &:disabled {
      background: $color-ghost-white;
      border-color: rgba(70, 79, 96, 0.16) !important;
      color: $color-roman-silver !important;
    }
  }

  &-primary {
    background: $color-majorelle-blue;
    border-color: $color-majorelle-blue;
    color: $color-white;

    &:hover {
      background: $color-ocean-blue;
      border-color: $color-ocean-blue !important;
      color: $color-white !important;
    }

    &:disabled {
      background: $color-maximum-blue-purple;
      border-color: $color-maximum-blue-purple !important;
      color: $color-lavender;
    }
  }

  &-red {
    background: $color-red;
    border-color: $color-red;
    color: $color-white;

    &:hover {
      background: $color-rusty-red;
      border-color: $color-rusty-red !important;
      color: $color-white !important;
    }

    &:disabled {
      background: $color-begonia;
      border-color: $color-begonia !important;
      color: $color-lavender;
    }
  }

  &-icon {
    width: 22px !important;
    height: 22px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    box-shadow: none;

    & .ant-wave {
      display: none;
    }

    &:disabled {
      background: transparent;
    }
  }

  &-link {
    & span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
  }

  &-tag {
    padding: 0;

    & * {
      font-family: $fontFamily-Roboto-Light;
    }
  }
}

.spin-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & .ant-spin {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  & .ant-modal-content {
    padding: 10px 12px;
  }

  & .ant-modal-footer {
    margin: 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

.modal-gray {
  border-radius: 8px;

  & .ant-modal-content {
    padding: 0;
  }

  &__body {
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    background: $color-ghost-white;
    border-bottom: 1px solid $color-lavender;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__header {
    margin-bottom: 16px;
    font-family: $fontFamily-Roboto-Bold;
    font-size: 20px;
    line-height: 28px;
  }

  &__title {
    margin-bottom: 8px;
    font-size: $fontSize-sm;
  }

  &__footer {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  &__error {
    font-family: $fontFamily-Roboto-Light;
    font-size: $fontSize-xsm;
    color: $color-red;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-lavender;
  border: 1px solid rgba(210, 213, 220, 0.5);
  border-radius: 6px;
  font-family: $fontFamily-Roboto-Medium;
  font-size: 20px;
  color: $color-majorelle-blue;
  text-transform: uppercase;
}

.cascader-modal {
  & .ant-modal-content {
    padding: 0;
    display: flex;
    justify-content: center;
    background: transparent;
    box-shadow: none;

    & .ant-modal-body {
      max-width: 96vw;
      display: flex;
      justify-content: center;
    }

    & .ant-cascader-panel {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .ant-cascader-panel-empty {
      padding: 20px;
      background: $color-white;
    }

    & .ant-cascader-menu {
      max-height: 50vh;
    }
  }
}

.image {
  border-radius: 8px;
  overflow: hidden;

  & img {
    width: 100%;
    object-fit: cover;

    &.img-empty {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &-w67 {
    width: 67px;
    height: 60px;
  }

  &-w73 {
    width: 73px;
    height: 73px;
  }

  &-w103 {
    width: 103px;
    height: 103px;
  }

  &-w156 {
    width: 156px;
    height: 156px;
  }
}

.form-select-with-custom-options-space {
  width: 100%;
  padding: 0 0 4px;

  & .ant-space-item:nth-child(1) {
    width: 100%;
  }
}

.autocomplete-with-action {
  & .ant-select {
    &-selector {
      padding: 0 !important;
    }

    &-selection-search-input {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    white-space: normal;
  }

  &__btn {
    padding: 0;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $color-majorelle-blue;

    & .ant-wave {
      display: none;
    }

    &:hover {
      background-color: transparent;
      color: $color-majorelle-blue !important;
    }

    &:disabled {
      background: transparent;
      color: $color-metallic-silver !important;
    }
  }
}

.workplace-nav {
  width: 100%;
  max-width: 228px;
  background: transparent !important;
  border: none !important;

  &__link {
    &.ant-menu-item {
      height: 48px;
      margin: 0;
      padding: 0;
      padding-left: 10px;
      border-radius: 0;
      line-height: 48px;
      text-transform: uppercase;

      &:hover {
        background: $color-ghost-white !important;
      }

      &-selected {
        background: $color-lavender;

        &:hover {
          background: $color-lavender !important;
        }

        a {
          font-family: $fontFamily-Roboto-Bold;
          color: $color-black !important;
        }
      }
    }
  }
}

.content-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
}

.tree-scroll-container {
  position: relative;
  overflow: scroll;
  scrollbar-color: $color-dark-electric-blue transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-dark-electric-blue;
    border-radius: 6px;
  }

  & .ant-tree {
    min-width: 100%;
    position: absolute;
  }
}

.status {
  padding: 3px 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  font-family: $fontFamily-Roboto-Medium;
  font-size: $fontSize-xsm;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 2px;
  }

  &.gray {
    background: $color-bright-gray;
    color: $color-dark-electric-blue;

    &::before {
      background: $color-dark-electric-blue;
    }
  }

  &.green {
    background: $color-honeydew;
    color: $color-islamic-green;

    &::before {
      background: $color-islamic-green;
    }
  }

  &.red {
    background: $color-lavender-blush;
    color: $color-rusty-red;

    &::before {
      background: $color-rusty-red;
    }
  }

  &.blue {
    background: $color-lavender;
    color: $color-majorelle-blue;

    &::before {
      background: $color-majorelle-blue;
    }
  }

  &.orange {
    background: #fcf2e6;
    color: $color-windsor-tan;

    &::before {
      background: $color-windsor-tan;
    }
  }
}
