.success-card {
  & .ant-drawer {
    &-close {
      background-color: $color-general-white !important;
    }

    &-wrapper-body {
      background-color: $color-general-bright-green;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    display: block;
  }
}
