.user-frame {
  max-width: 381px;
  width: 100%;
  border: 1px solid rgba(134, 143, 160, 0.25);
  border-radius: 8px;

  &__body {
    min-height: 114px;
    padding: 16px 20px;
    background: $color-ghost-white;
    border-bottom: 1px solid $color-lavender;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &-title {
      margin-bottom: 12px;
      font-family: $fontFamily-Roboto-Regular;
      color: $color-independence;
    }

    &-description {
      font-family: $fontFamily-Roboto-Light;
      color: $color-independence;
    }

    &-description-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }

    &-description-item {
      padding: 2px 8px;
      display: inline-block;
      background: $color-white;
      border: 1px solid rgba(70, 79, 96, 0.16);
      border-radius: 4px;
      font-family: $fontFamily-Roboto-Light;
      color: $color-independence;
    }
  }

  &__footer {
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}