.contract {
  padding: 40px;
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    & * {
      font-size: $fontSize-lg;
      text-transform: uppercase;
    }

    &-prefix {
      color: $color-metallic-silver;
    }
  }

  &__container {
    width: 100%;
  }
}

.contract-row {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-lavender;
  line-height: 20px;

  &__description {
    font-size: $fontSize-xsm;
    color: $color-metallic-silver;
  }

  &__select {
    width: min-content;
  }

  &.delivery:hover {
    cursor: pointer;
  }
}

.contract-category-settings-modal {
  &__tree {
    & .ant-tree-list-scrollbar {
      visibility: visible !important;

      &-thumb {
        margin-left: 4px;
        background: $color-dark-electric-blue !important;
      }
    }
  }
}

.contract-brand-settings-modal {
  &__scroll-container {
    height: 300px;
    overflow: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  &__checkbox {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;

    &.ant-checkbox-wrapper::after {
      display: none;
    }
  }
}
