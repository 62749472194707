.specification-list {
  width: 100%;
  height: 100%;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  
  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;

    & .ant-table-cell {
      white-space: nowrap;
    }

    &-item {
      &-status {
        width: max-content;
        padding: 2px 8px;
        border-radius: 4px;

        & span {
          display: flex;
          align-items: center;
          font-size: $fontSize-xsm;
  
          &::before {
            content: '';
            width: 6px;
            height: 6px;
            margin-right: 6px;
            display: inline-block;
            border-radius: 2px;
          }
        }

        &.ordered {
          background: #E1FCEF;
          color: #14804A;
  
          & span::before {
            background: #14804A;
          }
        }
  
        &.on_approval {
          background: $color-bright-gray;
          color: $color-dark-electric-blue;
  
          & span::before {
            background: $color-dark-electric-blue;
          }
        }
      }
    }
  }
}
