.preset {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 5px;
    display: inline-block;

    &.single-value {
      color: $color-independence;

      &-number {
        color: $color-majorelle-blue;
      }
    }

    &.multiple-value {
      color: $color-islamic-green;

      &-number {
        color: $color-majorelle-blue;
      }
    }

    &.range {
      color: $color-majorelle-blue;
    }

    &.checkbox {
      color: $color-rusty-red;
    }
  }
}