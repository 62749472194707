.redesign {
  .image {
    &-40 {
      width: 40px;
      height: 40px;

      & img {
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    &-60 {
      width: 60px;
      height: 60px;

      & img {
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    &-80 {
      width: 80px;
      height: 80px;

      & img {
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &-104 {
      width: 104px;
      height: 104px;

      & img {
        height: 100%;
        object-fit: contain;
      }
    }

    &-120 {
      width: 120px;
      height: 120px;

      & img {
        height: 100%;
        object-fit: contain;
      }
    }

    &-160 {
      width: 160px;
      height: 160px;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }

    &-640 {
      width: 640px;
      height: 640px;

      & img {
        height: 100%;
        border-radius: 16px;
        object-fit: contain;
      }
    }
  }
}
