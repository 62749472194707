.position {
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__title {
    font-size: $fontSize-lg;
  }

  &__error {
    font-family: $fontFamily-Roboto-Light;
    font-size: $fontSize-xsm;
    color: $color-red;
  }

  & .position-cascader {
    max-width: 613px;
  }
}

.position-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__presets {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__actions {
    display: flex;
    gap: 20px;
  }

  &__title {
    font-size: $fontSize-md;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    column-gap: 10px;

    &::after {
      content: '';
      flex-grow: 1000000000;
    }
  }

  &__item {
    min-width: 200px;
    flex-grow: 1;

    &-checkbox {
      margin-bottom: 0;
    }
  }
}

.similar-positions {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    font-size: $fontSize-md;
  }
}
