.properties-settings {
  padding: 40px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: $screen-maxWidth-lg) {
  .properties-settings {
    flex-direction: column;
  }
}
