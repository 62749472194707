.profile {
  height: 100%;
  display: flex;
  align-items: flex-start;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }

  &__content {
    padding: 92px 80px 0;
    height: 100%;
    flex: 1;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__details {
    margin-bottom: 72px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__block {
    margin-bottom: 52px;

    &-title {
      margin-bottom: 32px;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-field {
      max-width: 544px;
      flex: 1;
    }
  }

  &__settings {
    padding: 136px 80px 72px;
    max-width: 580px;
    height: 100%;
    flex: 1;
    border-left: 1px solid $color-outline-grey;

    &-container {
      margin-bottom: 72px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
      }
    }
  }
}
