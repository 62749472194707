.listing {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;

    &-spin {
      flex: 1;

      & .ant-spin-container {
        height: 100%;
      }
    }
  }

  &__header {
    display: none;
  }

  &__filter {
    position: relative;
    width: 371px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    & .ant-form-item {
      margin-bottom: 0;
    }

    &_header {
      padding: 24px 34px 26px 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &-title {
        font-family: $fontFamily-Roboto-Bold;
        font-size: $fontSize-md;
      }
    }

    &_seller-manager-block {
      padding: 24px 34px 26px 17px;

      &-title {
        margin-bottom: 8px;
        font-family: $fontFamily-Roboto-Bold;
        font-size: $fontSize-md;
      }

      & .ant-select {
        &-arrow {
          transform: rotate(0deg);
        }
      }
    }

    &_body {
      padding: 20px 20px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    &_item {
      &-hidden {
        display: none;
      }
      
      &-checkbox-group {
        &-multi-columns {
          display: grid;
          grid-auto-flow: dense;
          grid-template-columns: repeat(auto-fill, minmax(150px, max-content));
        }

        &-single-columns {
          display: grid;
          grid-auto-flow: dense;
          grid-template-columns: 1fr;
        }
      }

      & .ant-checkbox {
        &-wrapper {
          align-items: center;
        }
      }

      &-range {
        & .ant-form-item {
          max-width: 100%;
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 46px;

    &_header {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.listing-category-list {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(257px, max-content));
  gap: 30px;

  &__skeleton .ant-skeleton-input {
    width: 257px !important;
    height: 70px !important;
  }

  &__item {
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 0;
    box-sizing: border-box;
    white-space: normal;
    word-break: break-word;

    & span {
      flex: 1;
      text-align: start;
    }
  }
}

@media (max-width: 1440px) {
  .listing {
    &__container {
      flex-direction: column;
    }
    

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    &__filter {
      width: 100%;
    }

    &__content {
      &_header {
        display: none;
      }
    }
  }
}
