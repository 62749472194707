.supply-schedule {
  margin-bottom: 40px;
  width: 560px;

  &__title {
    margin-bottom: 20px;
    font-family: $fontFamily-Roboto-Bold;
    font-size: $fontSize-xsm;
    text-transform: uppercase;
  }

  &__name {
    margin-bottom: 10px;
    font-size: $fontSize-xsm;
  }

  &__table {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr max-content;
    row-gap: 10px;
  }

  &__item {
    display: contents;

    &-checkbox {
      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    &-count {
      width: max-content;
      flex: 1;
      height: 22px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      gap: 2px;
      border: 1px solid $color-anti-flash-white;
      border-radius: 4px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }

    &-total-amount {
      width: max-content;
      height: 22px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      gap: 2px;
      border: 1px solid $color-anti-flash-white;
      border-radius: 4px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }

    &-delivery-date {
      width: max-content;
      height: 22px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      gap: 2px;
      border: 1px solid $color-anti-flash-white;
      border-radius: 4px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }

    &-status {
      width: max-content;
      justify-self: flex-end;
    }

    &-menu {
      &.cancel .ant-btn {
        & span {
          color: $color-windsor-tan !important;
        }

        &:disabled span {
          color: $color-roman-silver !important;
        }
      }

      &.delete .ant-btn {
        & span {
          color: $color-red !important;
        }

        &:disabled span {
          color: $color-roman-silver !important;
        }
      }
    }
  }

  &__empty {
    font-size: $fontSize-xsm;
  }
}

.supply {
  &__price-input {
    height: 22px;
    border-radius: 4px;
    border-color: $color-anti-flash-white !important;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

    &:focus-within {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    }

    & .ant-input-number-input {
      padding: 0 4px !important;
      height: 22px;
      font-size: $fontSize-xsm;
      text-align: center;
      line-height: 0;
    }
  }

  &__date-picker {
    width: max-content;
    min-width: auto;
    height: 22px;
    border-color: $color-anti-flash-white !important;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) !important;

    & .ant-picker-input {
      & input {
        font-family: $fontFamily-Roboto-Light !important;
        font-size: $fontSize-xsm;
      }
    }
  }
}
