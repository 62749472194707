.stock-list {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;

    &-item {
      &-description {
        font-size: $fontSize-xsm;
        color: $color-metallic-silver;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.stock-form-modal {
  &__form {
    & .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title {
    margin-bottom: 0;
  }
}
