.goods-item {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  border-bottom: 1px solid $color-anti-flash-white;

  &__checkbox {
    height: 103px;
  }

  &__image {
    cursor: pointer;
  }

  &__container {
    height: 103px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__vendor-code {
    height: 22px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: $color-white;
    border: 1px solid $color-anti-flash-white;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

    & span {
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }
  }

  &__total-amount {
    height: 22px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: $color-white;
    border: 1px solid $color-anti-flash-white;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

    & span {
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }
  }

  &__delivery-date {
    height: 22px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: $color-white;
    border: 1px solid $color-anti-flash-white;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

    & span {
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }
  }

  &__ordered-count {
    height: 22px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    gap: 2px;
    border: 1px solid $color-anti-flash-white;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

    & span {
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }
  }

  &__name {
    margin-bottom: 4px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      position: relative;
      display: flex;
      align-items: flex-end;

      & span {
        display: flex;
        align-items: center;
        font-size: 10px;

        &:not(&:last-child):after {
          content: '';
          margin: 0 3px;
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: $color-black;
        }
      }
    }

    &-right {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      & .btn-icon {
        color: $color-roman-silver !important;

        & svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__link {
    font-size: 10px;
    color: $color-majorelle-blue !important;
  }
}
