.account-settings {
  margin-left: 348px;

  &__table {
    &-item {
      & .ant-form-item {
        margin-bottom: 0;
      }

      padding: 16px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

@media (max-width: $screen-maxWidth-xxl) {
  .account-settings {
    margin-left: 174px;
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .account-settings {
    margin-left: 87px;
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .account-settings {
    margin-left: 0px;
  }
}