.add-vendor-code-modal {
  max-width: 360px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 0.08), 0px 15px 35px -5px rgba(17, 24, 38, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  
  & .ant-modal-content {
    padding: 0;
  }
  
  &__body {
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    background: $color-ghost-white;
    border-bottom: 1px solid $color-lavender;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__title {
    margin-bottom: 8px;
    font-size: $fontSize-sm;
  }

  &__footer {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
}
