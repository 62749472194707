.integration-settings {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    &-item {
      padding: 16px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
