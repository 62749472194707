.subdivision-info {
  width: 100%;
  max-width: 792px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__label {
    padding-bottom: 8px;
  }

  &__subdivision-settings {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &_container {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  &__field {
    max-width: 405px;

    &-container {
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }

  &__sending-request {
    max-width: 304px;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

@media (max-width: $screen-maxWidth-xxl) {
  .subdivision-info {
    max-width: 422px;

    & .frame {
      max-width: 100%;
    }

    &__field {
      max-width: 100%;
    }

    &__sending-request {
      max-width: 100%;
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .subdivision-info {
    max-width: 100%;
  }
}
