.tree {
  &__bold-title {
    & .ant-tree-treenode.root .ant-tree-title {
      font-family: $fontFamily-Roboto-Bold;
    }
  }

  &__with-selected-icon {
    & .ant-tree-treenode-selected {
      & .ant-tree-iconEle {
        display: flex !important;
      }
    }
  }

  &__with-count {
    & .ant-tree-iconEle {
      display: flex !important;

      & div {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-roman-silver;
        border-radius: 50%;
        font-family: $fontFamily-Roboto-Bold;
        font-size: 10px;
        color: $color-white;
      }
    }
  }
}