.subdivision-workspaces {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__table {
    &_item {
      &-icon {
        width: 20px;
        height: 20px;
        background-image: url(../../images/tasks.svg);
      }

      &:hover &-icon {
        background-image: url(../../images/tasks-hover.svg);
      }
    }
  }
}

.create-workspace-modal {
  width: 360px !important;

  & .ant-modal-content {
    padding: 0;
  }
}
