.create-goods-form-modal {
  & .ant-modal {
    top: 16px;
    height: calc(100vh - 32px);
    padding-bottom: 0;

    &-content {
      height: 100%;
      padding: 20px 20px;
    }

    &-body {
      height: 100%;
    }
  }

  &__spin {
    width: 100%;
    height: 100%;

    & .ant-spin-container {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    position: relative;
    height: 100%;
  }

  &__form {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      &-label {
        padding-bottom: 8px;
      }

      &-select {
        & .ant-select-arrow {
          transform: rotate(90deg);
        }
      }

      &-checkbox {
        margin-bottom: 0;
      }

      &-submit {
        margin: auto auto 0 0;
      }
    }
  }

  &__title {
    margin-bottom: 50px;
    font-family: $fontFamily-Roboto-Light;
    text-transform: uppercase;
  }
}
