.previous-requests {
  position: relative;
  height: 100%;

  &__container {
    padding: 0 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;

    &-item {
      &-repeat {
        height: auto;
        padding: 2px 8px;
        background: $color-lavender;
        color: $color-majorelle-blue;
        border: none;
        box-shadow: none;

        &:hover {
          background: rgba(223, 222, 252, 1);
          color: rgba(55, 52, 163, 1) !important;
        }
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .previous-requests {
    &__container {
      padding: 0;
    }
  }
}
