.catalog {
  padding: 40px 40px 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    margin: 0 40px 52px;
    display: block;
  }
}

.catalog-breadcrumb {
  margin: 0 40px 32px;
}

.catalog-category-list {
  margin: 1px 40px 52px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  gap: 1px;

  &-toggle {
    padding: 20px 40px 20px 32px;
    min-height: 128px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: 1px solid $color-outline-grey;
    border-radius: 0;
    overflow: hidden;

    &-arrow {
      position: relative;
      display: flex;

      & svg {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
      }
    }

    &:hover {
      & .catalog__category-list-toggle-arrow::after {
        background-color: $color-background-pale-green;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:active {
      & .catalog__category-list-toggle-arrow::after {
        width: 320px;
        height: 320px;
        transform: translate(-50%, -50%) scale(0);
        animation: ripple 0.4s ease-out;
      }
    }
  }
}

.catalog-category-card {
  position: relative;
  padding: 20px 40px 20px 32px;
  min-height: 128px;
  display: flex;
  align-items: center;
  gap: 32px;
  border: none;
  outline: 1px solid $color-outline-grey;
  border-radius: 0;
  color: inherit !important;
  white-space: wrap;
  text-align: start;

  &__hover {
    position: absolute;
    padding: 20px 40px 20px 32px;
    margin-left: -32px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 32px;
    background-color: $color-general-bright-green;
    opacity: 0;

    &-arrow {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-background-light-bright-green;
      border-radius: 50%;
    }

    &:active {
      background-color: $color-pressed-bright-green;

      & .catalog-category-card__hover-arrow {
        background-color: $color-pressed-light-bright-green;
      }
    }
  }

  &:hover {
    & .catalog-category-card__hover {
      opacity: 1;
    }
  }

  &__skeleton {
    &-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & .ant-space-item {
        width: 100%;
      }
    }

    &-input {
      width: 100% !important;
    }
  }
}

.catalog-selector {
  &__dropdown {
    &-wrapper {
      display: flex;
      gap: 20px;
    }

    &-container {
      min-width: 300px;
    }
  }
}

.catalog-search {
  &__container {
    padding: 4px;
    padding-left: 0;
    flex: 1;
    height: 44px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid $color-outline-grey;
    border-radius: 44px;

    &:hover {
      border-color: $color-outline-dark-grey-hover;
    }

    &:focus-within {
      border-color: $color-general-bright-green;
    }

    &[disabled] {
      background-color: $color-hover-light-grey;
    }

    & .ant-input {
      padding: 0;
      padding-left: 20px;
      height: 100%;
      border: none;

      &:disabled {
        background-color: $color-hover-light-grey;
      }
    }
  }

  &__clear-btn {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &__catalog {
    padding: 0 18px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $color-background-pale-green;
    border-radius: 36px;
    white-space: nowrap;
  }

  &__dropdown {
    padding: 28px !important;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    &-categories {
      flex: 1;
      max-width: 480px;

      &-scroll {
        height: 350px;
        overflow-y: scroll;
      }

      &-item {
        position: relative;
        margin-bottom: 12px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;

        &-container {
          position: relative;
          padding: 10px 8px;
          display: flex;
          align-items: center;
          gap: 24px;
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0px;
          height: 0px;
          background: $color-background-dark-grey;
          border-radius: 50%;
          transform: translate(-50%, -50%) scale(0);
        }

        &:active::before {
          width: 300px;
          height: 300px;
          animation: ripple 0.4s ease-out;
        }

        &:hover {
          background-color: $color-hover-light-grey;
        }
      }
    }

    &-products {
      flex: 1;

      &-table {
        & table {
          border-spacing: 0 16px;
        }

        & .ant-table {
          &-row {
            & .ant-table-cell {
              vertical-align: middle;

              &:first-child {
                padding-left: 8px !important;
              }

              &:last-child {
                padding-right: 8px !important;
              }

              &::before {
                bottom: -8px !important;
              }
            }
          }
        }
      }

      &-item {
        &-name {
          max-width: 540px;
        }

        &-seller-code {
          width: 100%;
          display: inline-block;
          text-align: right;
        }
      }
    }
  }
}

.catalog-goods-list {
  margin: 0 40px;

  & .ant-table {
    &-expanded-row {
      & .ant-table-cell {
        padding: 0 0 20px 124px !important;
      }
    }
  }

  &-header {
    position: sticky;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &-full-screen {
    min-height: 100%;
  }

  &-end-of-list {
    & .ant-table {
      padding-bottom: 80px;

      &-row {
        &:last-child {
          & .ant-table-cell {
            border-bottom-color: $color-general-bright-green !important;
          }
        }
      }
    }
  }

  &-empty {
    flex: 1;

    & .ant-spin {
      &-nested-loading {
        height: 100%;
      }

      &-container {
        height: 100%;
      }
    }

    & .ant-table {
      height: 100%;

      &-container {
        height: 100%;
      }

      &-content {
        height: 100%;
      }

      & table {
        height: 100%;
      }
    }
  }

  &-filter-chips {
    position: sticky;
    padding: 0 40px 52px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: $color-general-white;
    gap: 8px 12px;
    z-index: 1;
    transition: all 0.3s ease;

    & .ant-select {
      width: max-content;
      min-height: 36px;
      background-color: $color-background-pale-green;
      border: 1px solid transparent !important;
      border-radius: 30px;

      &:hover {
        border-color: $color-general-bright-green !important;
      }

      &-selector {
        padding: 0 16px !important;
      }

      &-selection-item {
        color: $color-general-bright-green !important;
      }

      &-arrow {
        inset-inline-end: 8px;
      }

      &-open {
        border-color: $color-general-bright-green !important;
      }
    }
  }

  &-item {
    &-name {
      margin-bottom: 12px;
      display: inline-block;
    }

    &-in-basket-label {
      position: absolute;
      bottom: 20px;
      right: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
    }

    &-all-properties-btn {
      margin-bottom: 37px;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-container {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

.catalog-goods-list-filter {
  &__with-badge {
    position: relative;

    &::after {
      position: absolute;
      top: -2px;
      right: -2px;
      content: '';
      width: 16px;
      height: 16px;
      background-color: $color-general-bright-green;
      border: 3px solid $color-general-white;
      border-radius: 50%;
    }
  }

  &__item {
    margin-bottom: 72px;

    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-scrollable {
      padding-bottom: 12px;
      border-bottom: 1px solid $color-outline-light-grey;

      & .catalog-goods-list-filter__item-scroll-container {
        height: 266px;
        overflow-y: scroll;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-single {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-multiple {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.catalog-filter-chips {
  &__item {
    padding: 8px 8px 8px 16px;
    width: max-content;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $color-general-bright-green;
    border-radius: 36px;

    &-close {
      background-color: $color-background-light-bright-green !important;
      border-radius: 50%;

      &:hover {
        background-color: $color-pressed-light-bright-green !important;
      }
    }
  }

  &__clear-all {
    padding: 8px 8px 8px 16px;
    width: max-content;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $color-hover-light-grey;
    border-radius: 36px;

    &-close {
      background-color: $color-background-dark-grey !important;
      border-radius: 50%;

      &:hover {
        background-color: $color-outline-grey !important;
      }
    }
  }
}

.catalog-empty {
  margin: 80px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-title {
    margin-bottom: 12px;
  }

  &-description {
    margin-bottom: 28px;
  }
}

@media (max-width: 1440px) {
  .catalog-search {
    &__dropdown {
      &-categories {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .catalog-search {
    &__dropdown {
      flex-direction: column;

      &-categories {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@keyframes ripple {
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
