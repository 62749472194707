.empty-list {
  margin: 80px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__description {
    margin-bottom: 28px;
  }
}
