.shop {
  position: relative;
  height: 100%;
  display: flex;
  gap: 30px;

  &__header {
    display: flex;
    gap: 20px;
  }

  &__container {
    & .ant-spin-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__nav-container {
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__categories {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;

    &_item {
      width: 122px;
      height: auto;
      padding: 10px 4px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      border-radius: 0;
      box-sizing: border-box;
      white-space: normal;
      word-break: break-word;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 488px;

    &-title {
      font-family: $fontFamily-Roboto-Bold;
      font-size: $fontSize-md;
      text-wrap: nowrap;
    }

    &-select {
      & .ant-select-arrow {
        transform: rotate(0deg);
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .shop {
    & .product-search {
      width: 400px;
    }
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .shop {
    & .product-search {
      width: 100%;
      max-width: 100%;
    }

    &__header {
      flex-direction: column;
    }

    &__filter {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: $screen-maxWidth-md) {
  .shop {
    &__nav-container {
      align-items: flex-start;
      flex-direction: column-reverse;
      gap: 20px;
    }
  }
}
