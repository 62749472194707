.select-user-modal {
  &__title {
    margin-bottom: 8px;
  }

  &__table {
    & .ant-table-cell {
      cursor: pointer;
    }

    &_item {
      &.selected {
        background: $color-lavender;

        &:hover {
          & .ant-table-cell {
            background: $color-lavender !important;
          }
        }
      }

      &-user-email {
        font-size: $fontSize-xsm;
        color: $color-majorelle-blue;
      }
    }
  }
}