.account-list {
  padding: 40px;
  position: relative;
  margin: 0 auto;
  max-width: 972px;
  display: flex;
  flex-direction: column;


  &__header {
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__search {
    max-width: 353px;
  }

  &__create-btn {
    width: 179px;
  }

  &__cell {
    &-status {
      width: 128px;
    }

    &-id {
      width: 165px;
    }

    &-tariffPlan {
      width: 89px;
    }

    &-actions {
      width: 64px;
    }
  }

  &__item {
    &-id {
      font-family: $fontFamily-Roboto-Light;
    }

    &-name {
      font-family: $fontFamily-Roboto-Light;
    }

    &-tariffPlan {
      width: max-content;
      padding: 2px 8px;
      background: $color-lavender;
      border-radius: 4px;

      & span {
        display: flex;
        align-items: center;
        color: $color-majorelle-blue;
        font-size: $fontSize-xsm;

        &::before {
          content: '';
          width: 6px;
          height: 6px;
          margin-right: 6px;
          display: inline-block;
          border-radius: 2px;
          background: $color-majorelle-blue;
        }
      }
    }
  }
}

.create-account-modal {
  width: 360px !important;

  & .ant-modal-content {
    padding: 0;
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .account-list {
    &__cell {
      &-status {
        width: 114px;
        padding: 8px 8px !important;
      }
  
      &-id {
        width: 50px;
        padding: 8px 8px !important;
      }

      &-name {
        padding: 8px 8px !important;
      }
  
      &-tariffPlan {
        width: 65px;
        padding: 8px 8px !important;
      }
  
      &-actions {
        width: 48px;
        padding: 8px 8px !important;
      }
    }
  }
}
