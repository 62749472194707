.need-list {
  padding: 40px 40px 0;
  height: 100%;
  display: flex;
  align-items: normal;
  flex-direction: column;
  gap: 0;

  &__subtitle {
    margin-bottom: 20px;
  }

  &__filters {
    margin-bottom: 52px;
    display: flex;
    gap: 12px;

    &-select {
      max-width: 280px;
    }
  }

  &__actions {
    position: sticky;
    top: 0;
    padding-bottom: 52px;
    min-height: 96px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: $color-general-white;
    z-index: 1;
  }

  &__spin {
    flex: 1;
    display: flex;
    flex-direction: column;

    & .ant-spin-container {
      flex: 1;
    }
  }

  &__group {
    margin-bottom: 72px;

    &-date {
      margin-bottom: 12px;
      display: block;
      font-family: $ff-Onest-Medium;
      font-size: $fs-l;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__table {
    flex: 1;

    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }

      &-expanded-row {
        & .ant-table-cell:first-child {
          padding: 0;
          border-bottom: none;

          & .ant-table {
            margin: 0 !important;

            &-row {
              & .ant-table-cell:first-child {
                padding: 20px;
              }
            }
          }
        }
      }
    }

    &-expandable-row {
      & .ant-checkbox {
        padding-left: 0 !important;
      }
    }

    &-item {
      &-id {
        display: block;
        white-space: nowrap;
      }

      &-status {
        display: flex;
        justify-content: flex-end;
      }

      &-legal {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-images-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        & .ant-image {
          border: 1px solid $color-outline-invisible-grey;
          border-radius: 4px;
        }
      }

      &-image-placeholder {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-hover-light-grey;
        border-radius: 4px;
      }
    }

    &-end-of-list {
      & .ant-table {
        padding-bottom: 80px;

        &-row {
          &:last-child {
            & .ant-table-cell {
              border-bottom-color: $color-general-bright-green !important;
            }
          }
        }
      }
    }

    &-empty {
      flex: 1;

      & .ant-spin {
        &-nested-loading {
          height: 100%;
        }

        &-container {
          height: 100%;
        }
      }

      & .ant-table {
        height: 100%;

        &-container {
          height: 100%;
        }

        &-content {
          height: 100%;
        }

        & table {
          height: 100%;
        }
      }
    }
  }
}

.need-list-header {
  display: flex;
  justify-content: flex-end;

  &__settings-popover {
    display: flex;
    flex-direction: column;

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.need-list-item-progress {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.need-list-empty {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  &__wrapper {
    margin: 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  &-container {
    max-width: 530px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  &-title {
    margin-bottom: 12px;
  }

  &-description {
    margin-bottom: 28px;
  }
}

.need-card {
  &.nested {
    & .ant-drawer {
      &-header-title {
        justify-content: flex-start;
      }
    }
  }

  & .ant-drawer {
    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  &__created-at {
    margin-bottom: 12px;
    display: block;
  }
}

.need-card-info {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__goods {
    margin-bottom: 32px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $color-hover-light-grey;
    border-radius: 8px;

    &-name {
      margin-bottom: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__table {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 0;

    &-row {
      display: contents;
    }

    &-row-edit {
      position: relative;
      margin: -10px -8px 10px;
      padding: 10px 8px;
      display: flex;
      align-items: center;
      border-radius: 8px;

      &:hover {
        background-color: $color-hover-light-grey;
      }

      &-item {
        &-label {
          width: 320px;
          display: block;
        }

        &-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          &.ant-dropdown-open span {
            color: $color-outline-grey;
          }
        }

        &-dropdown {
          min-width: 640px !important;
        }
      }
    }
  }

  &__delivery {
    margin-bottom: 132px;
    flex: 1;

    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 0;
    }

    &-row {
      display: contents;
    }
  }
}

.need-card-supplies {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__spin {
    flex: 1;
    display: flex;
    flex-direction: column;

    & .ant-spin-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__empty {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__change-badge {
    margin-bottom: 32px;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $color-background-black;
    border-radius: 8px;

    &-title {
      margin-bottom: 12px;
      display: block;
    }
  }
}

.need-card-history-item {
  display: flex;
  gap: 20px;

  &__icon-container {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-hover-light-grey;
    border-radius: 6px;
  }

  &__line {
    position: relative;
    flex: 1;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 50%;
      bottom: 4px;
      width: 2px;
      background-color: $color-hover-light-grey;
      transform: translateX(-50%);
    }
  }

  &__container {
    padding-bottom: 32px;
    flex: 1;
  }

  &:last-child {
    & .need-card-history-item__line {
      &::before {
        display: none;
      }
    }
  }
}
