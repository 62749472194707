.settings {
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 30px;

  &__content {
    position: relative;
    height: 100%;
    flex: 1;
  }
}