.legal-entity-list {
  max-width: 1122px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;

    &-item {
      &-inn {
        font-size: $fontSize-xsm;
        color: $color-metallic-silver;
      }
    }
  }
}

.legal-modal {
  &__customers {
    &-label {
      padding-bottom: 8px;
    }

    &-form {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &-item {
      display: flex;
      align-items: center;
    }

    & .ant-empty {
      margin: 0;
    }

    & .btn-icon {
      color: $color-red !important;
    }
  }
}
