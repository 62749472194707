.private-layout {
  height: 100%;
  display: flex;

  &__container {
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
  }
}
