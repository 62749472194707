.need-status {
  position: relative;
  padding: 7px 10px 7px 12px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.created {
    background-color: $color-hover-light-grey;
    color: $color-general-dark-grey;

    &::before {
      background-color: $color-general-dark-grey;
    }
  }

  &.pending {
    background-color: $color-background-pale-yellow;
    color: $color-general-yellow;

    &::before {
      background-color: $color-general-yellow;
    }
  }

  &.approved {
    background-color: $color-background-pale-green;
    color: $color-general-bright-green;

    &::before {
      background-color: $color-general-bright-green;
    }
  }

  &.cancelled {
    background-color: $color-background-pale-red;
    color: $color-general-red;

    &::before {
      background-color: $color-general-red;
    }
  }
}
