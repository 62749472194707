.header {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-lavender;

  & a {
    text-decoration: none;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $fontFamily-Roboto-Bold;
    font-size: $fontSize-lg;
    color: $color-majorelle-blue;
    text-transform: uppercase;
  }

  &__profile {
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__user {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
  }
}