.basket {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0;

  &__container {
    padding: 40px 40px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 0;
  }

  &__header {
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  &__content {
    position: relative;
    padding: 0 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__title {
    position: relative;
    margin-bottom: 52px;
    width: max-content;
    text-transform: none;

    &-count {
      position: absolute;
      top: 0;
      margin-left: 4px;
    }
  }

  &__spin {
    height: 100%;
    flex: 1;
    display: flex;

    & .ant-spin {
      &-container {
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }
  }

  &__scroll {
    position: relative;
    height: 100%;
    overflow: hidden;

    &-container {
      position: absolute;
      padding-bottom: 149px;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__goods-list {
    &.table-expandable {
      & .ant-table {
        &-row {
          cursor: default;

          & .ant-table-cell {
            border: none !important;
          }
        }

        &-selection-column {
          vertical-align: middle !important;

          & .ant-checkbox {
            padding: 0 !important;
          }
        }
      }

      & .ant-table-expanded-row {
        cursor: default;

        & .ant-table-cell {
          padding: 0 0 20px 204px !important;
          border-bottom: 1px solid $color-outline-light-grey !important;
        }
      }
    }

    &-item {
      &-name {
        margin-bottom: 12px;
        display: inline-block;
      }

      &-price {
        margin-bottom: 4px;
        display: block;
        white-space: nowrap;
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-notification {
        margin-bottom: 32px;
        padding: 16px;
        background-color: $color-background-black;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

.basket-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__description {
    margin-bottom: 28px;
  }
}

.basket-progress {
  position: absolute;
  bottom: 0;
  padding: 40px 0;
  width: calc(100% - 80px);
  background-color: $color-general-white;

  &__header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__progress {
    margin-bottom: 6px;

    & .ant-progress-steps-item {
      margin: 0;
      width: 10px !important;
      height: 10px !important;
      border-radius: 50%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.basket-request-composition {
  flex: 1;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $color-outline-grey;

  &__header {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__body {
    position: relative;
    padding: 12px 80px 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;

    &-container {
      flex: 1;
    }

    &-title {
      margin-bottom: 52px;
      display: inline-block;
    }

    &-block {
      margin-bottom: 72px;

      &-title {
        margin: 0 8px 24px;
        padding-bottom: 12px;
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid $color-outline-grey;
      }

      &-container {
        margin: 0 8px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px 40px;

        &-row {
          display: contents;
        }
      }

      &:last-child {
        margin-bottom: 52px;
      }

      &-table {
        & .ant-table {
          &-row {
            & .ant-table-cell {
              border-bottom: none !important;
              vertical-align: top;
              line-height: 180%;

              &:first-child {
                padding-left: 8px !important;
                padding-right: 0;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                padding-left: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                text-align: start;
                white-space: normal;
              }
            }
          }
        }

        &.selectable {
          & .ant-table {
            &-row {
              &:hover {
                background-color: $color-hover-light-grey;
              }

              &:active {
                background-color: $color-pressed-light-grey;
              }
            }
          }
        }
      }

      &-select {
        height: auto;
        min-height: auto !important;

        &.ant-select {
          border: none !important;
        }

        & .ant-select {
          &-selection-item {
            line-height: 140% !important;
            white-space: normal;
          }

          &-arrow {
            top: 0;
            margin: 0;
            height: auto;
            inset-inline-end: 0;
          }
        }

        &-popup {
          width: 100%;
          max-width: 419px;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__notification {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $color-background-black;
    border-radius: 8px;

    &-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.basket-add-goods-button {
  & .ant-drawer-body {
    padding-bottom: 0 !important;
  }

  &__container {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__input {
    margin-bottom: 16px;
  }

  &__table {
    & table {
      border-spacing: 0 24px;
    }

    & .ant-table {
      &-row {
        & .ant-table-cell {
          &:first-child {
            padding-right: 0 !important;
            padding-left: 20px !important;
          }

          &:last-child {
            padding-right: 20px !important;
            text-align: start;
            white-space: normal;
          }

          &::before {
            bottom: -12px !important;
          }
        }
      }
    }

    &-item {
      &-name {
        margin-bottom: 12px;
        display: block;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.basket-more-button {
  & .ant-dropdown-menu-item-disabled {
    & span {
      color: $color-outline-grey !important;
    }
  }
  &__menu {
    &-item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 1440px) {
  .basket {
    &__container {
      padding: 20px 20px 0;
    }

    &__goods-list {
      & .ant-table {
        &-row {
          & .ant-table-cell {
            padding: 8px !important;
          }
        }

        &-expanded-row {
          & .ant-table-cell {
            padding: 0 0 8px 148px !important;
          }
        }
      }
    }
  }

  .basket-request-composition {
    max-width: 460px;

    &__header {
      padding: 20px;
    }

    &__body {
      padding: 12px 20px 20px;
    }
  }
}
