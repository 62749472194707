@import '../abstracts/variables';

.ant-input {
  padding: 4px 12px;
  border: 1px solid rgba(134, 143, 160, 0.16);
  font-family: $fontFamily-Roboto-Light;

  &::placeholder {
    color: $color-metallic-silver;
  }

  &:hover {
    background: $color-white;
    border-color: rgba(134, 143, 160, 0.4);
  }

  &:focus {
    border-color: rgba(134, 143, 160, 0.4);
  }

  &:focus-within {
    background: $color-white;
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
  }

  &-affix-wrapper {
    padding: 4px 12px;
    align-items: center;
    border: 1px solid rgba(134, 143, 160, 0.16);

    &:hover {
      background: $color-white;
      border-color: rgba(134, 143, 160, 0.4) !important;
    }

    &:focus-within {
      background: $color-white;
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
    }

    &-focused {
      border-color: rgba(134, 143, 160, 0.4);
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
    }

    &-disabled {
      background: $color-ghost-white;
      border: 1px solid rgba(134, 143, 160, 0.16);

      &:hover {
        background: $color-ghost-white;
      }

      & .ant-input-disabled {
        border: none !important;
      }
    }
  }

  &-prefix {
    width: 16px;
    height: 16px;
    margin-inline-end: 9px !important;

    & svg {
      width: 100%;
      height: 100%;
    }

    & path {
      fill: $color-roman-silver;
    }
  }

  &-password {
    & .ant-input-suffix {
      cursor: pointer;
    }
  }

  &-number-group-wrapper {
    width: 100%;

    & .ant-input-number-affix-wrapper {
      border: 1px solid rgba(134, 143, 160, 0.16);
      border-right: none;
    }

    & .ant-input-number-group-addon {
      background: transparent;
      border: 1px solid rgba(134, 143, 160, 0.16);
    }

    &:hover {
      & .ant-input-number-affix-wrapper {
        border-color: rgba(134, 143, 160, 0.4);
      }

      & .ant-input-number-group-addon {
        border-color: rgba(134, 143, 160, 0.4);
      }
    }

    & .ant-input-number-wrapper {
      &:focus-within {
        border-radius: 6px;
        box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
      }

      &-disabled {
        &:focus-within {
          box-shadow: none;
        }

        & .ant-input-number-affix-wrapper {
          background: $color-ghost-white;
          border: 1px solid rgba(134, 143, 160, 0.16);
          border-right: none;
        }

        & .ant-input-number-group-addon {
          background: $color-ghost-white;
          border: 1px solid rgba(134, 143, 160, 0.16);
          border-left: none;
        }

        &:hover {
          & .ant-input-number-affix-wrapper {
            border-color: rgba(134, 143, 160, 0.4);
          }

          & .ant-input-number-group-addon {
            border-color: rgba(134, 143, 160, 0.4);
          }
        }
      }

      & .ant-input-number-affix-wrapper-status-error {
        & .ant-input-number-prefix {
          color: $color-black !important;
        }

        &:hover {
          border-color: $color-red !important;
        }

        & + .ant-input-number-group-addon {
          border-color: $color-red;
        }
      }

      & .ant-input-number-affix-wrapper-status-error.ant-input-number-affix-wrapper-disabled {
        & .ant-input-number-prefix {
          color: $color-metallic-silver !important;
        }
      }

      &-disabled .ant-input-number-affix-wrapper-status-error {
        border: 1px solid rgba(134, 143, 160, 0.16);
        border-right: none;

        & + .ant-input-number-group-addon {
          border: 1px solid rgba(134, 143, 160, 0.16);
          border-left: none;
        }
      }

      &-disabled:hover .ant-input-number-affix-wrapper-status-error {
        border-color: rgba(134, 143, 160, 0.4) !important;
        border-right: none;

        & + .ant-input-number-group-addon {
          border-color: rgba(134, 143, 160, 0.4);
        }
      }
    }
  }

  &-group-wrapper {
    & .ant-input {
      border-right: none;
    }

    & .ant-input-group-addon {
      background: transparent;
      border: 1px solid rgba(134, 143, 160, 0.16);
    }

    &:hover {
      & .ant-input {
        border-right: none;
        border-color: rgba(134, 143, 160, 0.4);
      }

      .ant-input-group-addon {
        border-color: rgba(134, 143, 160, 0.4);
      }
    }

    &-disabled {
      & .ant-input-group-addon {
        background: $color-ghost-white;
        border: 1px solid rgba(134, 143, 160, 0.16);
      }
    }

    &:focus-within {
      border-radius: 6px;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
    }

    &-disabled:focus-within {
      box-shadow: none;
    }

    & .ant-input:focus {
      box-shadow: none;

      & + .ant-input-group-addon {
        border-color: rgba(134, 143, 160, 0.4);
      }
    }

    & .ant-input-status-error {
      clip-path: inset(-5px 0px -5px -5px);

      & + .ant-input-group-addon {
        border-color: $color-red;
      }

      &:hover {
        border-color: $color-red !important;
      }

      &:focus {
        box-shadow: none;

        & + .ant-input-group-addon {
          border-color: $color-red;
        }
      }
    }

    &-disabled.ant-input-group-wrapper-status-error {
      & .ant-input-group-addon {
        border: 1px solid rgba(134, 143, 160, 0.16);
        border-left: none;
      }

      &:hover {
        & .ant-input {
          border-color: rgba(134, 143, 160, 0.4) !important;
        }

        .ant-input-group-addon {
          border-color: rgba(134, 143, 160, 0.4);
        }
      }
    }
  }

  &-number {
    width: 100%;
    border: 1px solid rgba(134, 143, 160, 0.16);

    &-disabled {
      background: $color-ghost-white !important;
    }

    &-input {
      padding: 4px 12px !important;
      font-family: $fontFamily-Roboto-Light !important;
    }

    &:hover {
      background: $color-white;
      border-color: rgba(134, 143, 160, 0.4);
    }

    &:focus-within {
      background: $color-white;
      border-color: rgba(134, 143, 160, 0.4);
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
    }

    &-prefix {
      padding-left: 11px;
    }
  }

  &-number-affix-wrapper {
    width: 100%;
    padding: 0;
    border: 1px solid rgba(134, 143, 160, 0.16);

    &-disabled {
      background: $color-ghost-white !important;
    }

    &:hover {
      border-color: rgba(134, 143, 160, 0.4) !important;
    }

    &:focus-within {
      border-radius: 6px;
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
    }

    & .ant-input-number-prefix {
      color: $color-black !important;
    }
  }

  &-suffix {
    font-family: $fontFamily-Roboto-Light;
    color: $color-metallic-silver;
  }
}

.ant-form {
  width: 100%;

  &-item {
    &-explain-error {
      font-family: $fontFamily-Roboto-Light;
      font-size: $fontSize-xsm;
    }
  }
}

.ant-tree {
  background: transparent;

  &-treenode {
    position: relative;
    width: 100%;
    padding: 0 !important;
    display: flex;
    align-items: center !important;

    &-motion {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &-selected {
      background-color: $color-lavender;

      &::before {
        background: $color-lavender !important;
      }

      &:hover {
        background-color: $color-lavender;
      }

      & .ant-tree-title {
        color: $color-black;
      }
    }

    &-disabled .ant-tree-node-content-wrapper {
      & .ant-tree-title {
        color: $color-roman-silver;
      }
    }
  }

  &-switcher {
    display: flex;
    align-items: center;
    justify-content: center;

    &_open svg {
      transform: rotate(90deg);
    }
  }

  & .ant-tree-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $fontFamily-Roboto-Light;
    color: $color-black;

    & * {
      font-family: $fontFamily-Roboto-Light;
    }
  }

  &-node-content-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    order: 1;

    &:hover {
      background-color: transparent !important;
    }

    &.ant-tree-node-selected {
      background-color: transparent !important;
    }
  }

  &-iconEle {
    display: none !important;
    align-items: center;
    width: auto !important;
  }

  &-checkbox {
    order: 2;

    &:hover {
      & .ant-tree-checkbox-inner {
        border-color: rgba(70, 79, 96, 0.32) !important;
      }
    }

    &-checked &-inner {
      background-color: $color-majorelle-blue !important;
      border-color: $color-majorelle-blue !important;
    }

    &-indeterminate .ant-tree-checkbox-inner::after {
      background-color: $color-majorelle-blue !important;
    }
  }
}

.ant-layout {
  background: $color-white;
}

.ant-btn {
  &-link {
    height: auto;
    padding: 0;

    & span {
      color: $color-majorelle-blue;
    }

    &:disabled {
      & span {
        color: $color-maximum-blue-purple;
      }
    }
  }
}

.ant-select {
  width: 100%;

  &-selector {
    padding: 1px 12px !important;
    border: 1px solid rgba(134, 143, 160, 0.16) !important;

    &:hover {
      border-color: rgba(134, 143, 160, 0.4);
    }
  }

  &:hover {
    & .ant-select-selector {
      border-color: rgba(134, 143, 160, 0.4) !important;
    }
  }

  &-focused {
    & .ant-select-selector {
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4) !important;
    }
  }

  &-selection-search-input {
    font-family: $fontFamily-Roboto-Light !important;
  }

  &-selection-item {
    font-family: $fontFamily-Roboto-Light;
    line-height: 24px !important;
  }

  &-item-option {
    &-content {
      font-family: $fontFamily-Roboto-Light;
    }

    &-active {
      background-color: $color-ghost-white !important;
    }

    &-selected {
      background-color: $color-lavender !important;
    }
  }

  &-selection-placeholder {
    font-family: $fontFamily-Roboto-Light;
    line-height: 24px !important;
    color: $color-metallic-silver;
  }

  &-arrow {
    transform: rotate(90deg);
  }

  &-status-error .ant-select-selector {
    border: 1px solid $color-red !important;
    box-shadow: 0px 0px 6px 0px $color-red !important;
  }
}

.ant-checkbox {
  & .ant-wave {
    --wave-color: #4945c4 !important;
  }

  &-wrapper:hover {
    & .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-ocean-blue !important;
    }

    & .ant-checkbox-inner {
      border-color: rgba(70, 79, 96, 0.32) !important;
    }
  }

  &-checked &-inner {
    background-color: $color-majorelle-blue;
    border-color: $color-majorelle-blue !important;
  }

  &-wrapper-disabled {
    & .ant-checkbox-inner:after {
      border-color: $color-white;
    }
  }

  &-indeterminate .ant-checkbox-inner:after {
    background-color: $color-majorelle-blue;
  }
}

.ant-tag {
  position: relative;
  max-width: 300px;
  height: 22px;
  margin-right: 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
  background: $color-lavender;
  border-color: $color-lavender;
  color: $color-majorelle-blue;
  overflow: hidden;

  &-close-icon {
    position: absolute;
    top: 5px;
    right: 0px;
    width: 18px;
    height: 12px;
    background: $color-lavender;

    & svg {
      width: 12px;
      height: 12px;
    }
  }

  &:hover {
    background: rgba(223, 222, 252, 1);
    color: rgba(55, 52, 163, 1);

    & .ant-tag-close-icon {
      background: rgba(223, 222, 252, 1);

      & svg {
        fill: $color-red;
      }
    }
  }
}

.ant-list {
  &-item {
    height: 24px;
    padding: 0 4px !important;
    margin-bottom: 4px;
    border-block-end: none !important;
    font-family: $fontFamily-Roboto-Light;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &.selected {
      background: $color-lavender;
    }
  }
}

.ant-cascader {
  &-dropdown {
    box-shadow: none;
    overflow: visible;
  }

  &-menus {
    gap: 10px;
  }

  &-menu {
    min-height: 421px;
    padding: 20px !important;
    background: $color-white;
    border-inline-end: none !important;
    border-radius: 5px !important;
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);

    &-item {
      padding: 0 !important;
      flex-direction: row-reverse;
      border-radius: 0 !important;

      &-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        font-family: $fontFamily-Roboto-Light;

        &::after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
        }
      }

      &-expand-icon {
        height: 16px;
        margin: 0 10px 0 0 !important;
      }

      &:hover {
        background: $color-ghost-white !important;
      }

      &-active {
        background: $color-lavender !important;

        &:hover {
          background: $color-lavender !important;
        }

        & .ant-cascader-menu-item-content {
          &::after {
            background-image: url(../../images/success.svg);
          }
        }
      }

      &:not([title]),
      :not([title]):empty {
        & .ant-cascader-menu-item-content {
          display: block;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.ant-breadcrumb {
  &-link {
    font-size: $fontSize-xsm;
    color: $color-majorelle-blue;
    cursor: pointer;

    &:hover {
      color: $color-ocean-blue;
    }
  }

  &-separator {
    color: $color-majorelle-blue !important;
  }
}

.ant-collapse {
  &-header {
    padding-inline-start: 0 !important;
    padding: 0 !important;
    flex-direction: row-reverse;
    align-items: center !important;
    justify-content: flex-end;

    &-text {
      margin-inline-end: 0 !important;
      font-family: $fontFamily-Roboto-Bold;
      text-transform: uppercase;
      word-break: break-word;
    }
  }

  &-expand-icon {
    height: auto !important;
    padding-inline-end: 2px !important;
    margin-inline-start: 0 !important;
    transform: rotate(90deg);
  }

  &-content-box {
    padding: 6px 0 0 !important;
  }

  &-item {
    margin-bottom: 20px;
  }

  &-item-active {
    & .ant-collapse-expand-icon {
      transform: rotate(-90deg);
    }
  }

  & .ant-form-item {
    margin: 0;

    &-explain-error {
      position: absolute;
    }
  }
}

.anticon {
  &-exclamation-circle {
    color: $color-red;
  }

  &-info-circle {
    color: $color-islamic-green;
  }
}

.ant-tooltip {
  max-width: max-content;
}

.ant-segmented {
  background: $color-bright-gray;

  &-item {
    &-selected {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;

      & .ant-segmented-item-label {
        color: $color-majorelle-blue;
      }
    }
  }
}

.ant-upload {
  width: 100%;

  & .ant-btn {
    width: 100%;
    display: flex;
    align-items: center;
    border-color: rgba(134, 143, 160, 0.16);

    &:hover {
      border-color: rgba(134, 143, 160, 0.4) !important;
      color: initial !important;
    }

    & .ant-wave {
      --wave-color: #4945c4 !important;
    }

    & span:not(.ant-btn-icon) {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.ant-modal {
  padding-bottom: 0;
}

.ant-table {
  &-thead {
    & .ant-table-cell {
      background: transparent !important;

      &:before {
        display: none;
      }
    }
  }

  &-body {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ant-tabs {
  &-tab {
    &:hover {
      & .ant-tabs-tab-btn {
        color: $color-majorelle-blue !important;
      }
    }

    &-active {
      & .ant-tabs-tab-btn {
        color: $color-majorelle-blue !important;
      }
    }
  }

  &-ink-bar {
    background: $color-majorelle-blue !important;
  }
}

.ant-pagination {
  &-item {
    &-active {
      border-color: $color-ocean-blue !important;

      & a {
        color: $color-ocean-blue !important;
      }
    }
  }
}

.ant-spin {
  &-dot-item {
    background-color: $color-amazon !important;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-message {
  & .anticon {
    margin: 0 !important;
    flex: none;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
}
