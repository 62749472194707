.counter {
  padding: 0 4px;
  flex: 1;
  min-width: 110px;
  height: 44px;
  border: 1px solid $color-outline-grey;
  border-radius: 44px;

  &__focused {
    border-color: $color-general-bright-green;
  }

  & .ant-input-number {
    padding: 0;
    height: 100%;
    border: none;

    &-wrapper {
      height: 100%;
    }

    &-group-addon {
      padding: 0;
      background-color: transparent;
      border: none;
    }

    &-input-wrap {
      height: 100%;
    }

    &-input {
      height: 100%;
      padding: 0 !important;
      font-family: $ff-Onest-Medium;
      font-size: $fs-s;
      text-decoration: none;
      text-align: center;
    }
  }

  &__btn {
    padding: 0;
    width: 36px !important;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-hover-light-grey;
    border: none;
    outline: none;
    border-radius: 50%;
    box-shadow: none;

    & .icon-plus path,
    & .icon-minus path {
      stroke: $color-general-black;
    }

    &:hover {
      background-color: $color-pressed-light-grey;
    }

    &:active {
      background-color: $color-background-grey;
    }

    &:disabled {
      background-color: transparent;

      & .icon-plus path,
      & .icon-minus path {
        stroke: $color-outline-grey;
      }
    }
  }
}
