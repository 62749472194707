.sidebar-item {
  padding: 8px;
  min-height: 48px;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.6s ease 0s;

  &__content {
    width: 280px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__label {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    color: $color-general-dark-grey;
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all 0.6s ease 0s;

    & path {
      stroke: $color-general-white;
      transition: all 0.6s ease 0s;
    }

    & .icon-document {
      & path {
        &:nth-child(3) {
          stroke: transparent;
          fill: $color-general-white;
        }
      }
    }

    & .icon-tool {
      & path {
        stroke: $color-general-white;

        &:nth-child(4) {
          fill: $color-general-white;
        }
      }
    }

    &-badge {
      position: relative;

      &::after {
        position: absolute;
        bottom: 2px;
        right: 2px;
        content: '';
        width: 0px;
        height: 0px;
        background-color: $color-background-black;
        border: 0px solid $color-general-white;
        border-radius: 50%;
        transition: all 0s ease 0s;
      }
    }
  }

  &:hover {
    background-color: $color-background-grey;
  }

  &__active {
    background-color: $color-background-grey;

    & .sidebar-item__label {
      color: $color-general-black;
    }
  }
}

.sidebar {
  padding: 32px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  background-color: $color-hover-light-grey;
  border-right: 1px solid $color-outline-grey;
  transition: all 0.6s ease 0s;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & div[class='sidebar-item'] {
    background-color: transparent !important;
  }

  &__header {
    margin-bottom: 52px;
    transition: all 0.6s ease 0s;

    &-image {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.6s ease 0s;

      & img {
        width: 80px !important;
        height: 80px !important;
      }
    }

    &-label span {
      max-height: 42px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    & .sidebar-item {
      height: 96px;
      transition: all 0.6s ease 0s;

      &__active {
        & .sidebar__header-icon {
          & path {
            stroke: $color-general-black;
          }
        }
      }
    }
  }

  &__body {
    flex: 1;

    &-group {
      margin-bottom: 28px;

      &.orange-group {
        & .sidebar-item__icon {
          background-color: $color-background-yellow;
        }
      }

      &.dark-green-group {
        & .sidebar-item__icon {
          background-color: $color-general-dark-green;
        }
      }

      &.bright-green-group {
        & .sidebar-item__icon {
          background-color: $color-general-bright-green;
        }
      }
    }
  }

  &__footer {
    & .sidebar-item__icon {
      & path {
        stroke: $color-general-dark-grey;
      }
    }

    & .icon-profile {
      padding: 7px;
      min-width: 32px;
      min-height: 32px;
      background-color: $color-background-dark-grey;
    }
  }
}

.sidebar-collapsed {
  padding: 32px 16px;
  max-width: 80px;
  background-color: $color-general-white;

  & .sidebar-item {
    padding: 4px;
    transition: all 0.6s ease 0s;

    &__icon {
      width: 40px;
      height: 40px;
      background-color: transparent !important;

      & path {
        stroke: $color-general-dark-grey;
        transition: all 0s ease 0s;
      }

      & .icon-document {
        & path:nth-child(3) {
          fill: $color-general-dark-grey;
        }
      }

      & .icon-tool {
        & path {
          stroke: $color-general-dark-grey;

          &:nth-child(4) {
            fill: $color-general-dark-grey;
          }
        }
      }

      &-badge {
        &::after {
          width: 16px;
          height: 16px;
          border-width: 3px;
          transition:
            width 0.2s ease 0.5s,
            height 0.2s ease 0.5s,
            border-width 0.2s ease 0.5s;
        }
      }
    }

    &:hover {
      background-color: transparent;

      & .sidebar-item__icon {
        & path {
          stroke: $color-general-black;
        }

        & .icon-document {
          & path:nth-child(3) {
            stroke: transparent;
            fill: $color-general-black;
          }
        }

        & .icon-tool {
          & path {
            stroke: $color-general-black;

            &:nth-child(4) {
              fill: $color-general-black;
            }
          }
        }
      }
    }

    &__active {
      & .sidebar-item__icon {
        & path {
          stroke: $color-general-black;
        }

        & .icon-document {
          & path:nth-child(3) {
            stroke: transparent;
            fill: $color-general-black;
          }
        }

        & .icon-tool {
          & path {
            stroke: $color-general-black;

            &:nth-child(4) {
              fill: $color-general-black;
            }
          }
        }

        &-badge {
          &::after {
            border-color: $color-background-grey;
          }
        }
      }

      &:hover {
        background-color: $color-background-grey;

        & .sidebar-item__icon {
          &-badge {
            &::after {
              border-color: $color-background-grey;
            }
          }
        }
      }
    }
  }

  & .sidebar {
    &__header {
      margin-bottom: 100px;

      &-image {
        width: 40px;
        height: 40px;
      }

      & .sidebar-item {
        height: 48px;
      }
    }

    &__footer {
      & .icon-profile {
        min-width: 40px;
        min-height: 40px;
        background-color: $color-hover-light-grey !important;
        transition: all 0.6s ease 0s;
      }

      & .sidebar-item {
        &:hover {
          & .icon-profile {
            background-color: $color-background-grey !important;
          }
        }

        &__active {
          & .icon-profile {
            background-color: $color-background-grey !important;
          }
        }
      }
    }
  }
}
