.users {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  &__wrapper {
    width: 100%;
    max-width: 732px;
  }

  &__title {
    margin-bottom: 32px;
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__list {
    &_item {
      & * {
        font-family: $fontFamily-Roboto-Light;
      }

      &-cell {
        &.user-status {
          width: 48px;
        }

        &.role {
          width: 140px;
        }

        &.email {
          width: 200px;
        }
      }

      &-status {
        display: flex;
        align-items: center;

        & .success path {
          fill: $color-majorelle-blue;
        }
      }
    }
  }

  &__header {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-container {
      display: flex;
      gap: 10px;
    }

    &-actions {
      display: flex;
      gap: 40px;
    }

    &-tags {
      min-height: 22px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__filter {
    &-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      border-color: rgba(70, 79, 96, 0.16);

      & span {
        font-family: $fontFamily-Roboto-Light;
        color: $color-independence;
      }

      & svg:last-child {
        transform: rotate(90deg);
      }

      &:hover {
        border-color: rgba(70, 79, 96, 0.32) !important;

        & span {
          color: $color-black !important;
        }
      }
    }

    &-dropdown {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

@media (max-width: $screen-maxWidth-xxl) {
  .users {
    &__list {
      &_item {
        &-cell {
          padding-left: 8px !important;
          padding-right: 8px !important;

          &.user-status {
            width: 32px;
          }
  
          &.role {
            width: 120px;
          }
        }
      }
    }

    &__header {
      &-container {
        flex-direction: column;
      }

      &-actions {
        gap: 10px;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .users {
    flex-direction: column;
    justify-content: flex-start;

    &__wrapper {
      max-width: 100%;
    }

    &__header {
      &-container {
        flex-direction: row;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .users {
    &__header {
      &-container {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-md) {
  .users {
    &__header {
      &-actions {
        flex-direction: column;
      }
    }

    &__filter {
      &-btn {
        justify-content: space-between;
      }
    }
  }
}
