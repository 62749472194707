.discount {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  & .ant-input-number {
    max-width: 64px;
    width: max-content;
  }

  &__title {
    & * {
      font-size: $fontSize-lg;
      text-transform: uppercase;
    }

    &-parent {
      color: $color-metallic-silver;
    }
  }

  &__subtitle {
    font-size: $fontSize-lg;
  }

  &__basic-discount {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    line-height: 20px;
  }

  &__create-discount-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 23px;

    &-select {
      width: 320px;

      &-popup {
        & .ant-select-item {
          display: flex;
          align-items: center;

          &::before {
            content: '';
            width: 10px;
            height: 10px;
            margin-right: 10px;
            display: block;
            background-color: $color-islamic-green;
            border-radius: 2px;
          }
        }
      }
    }
  }

  &__table {
    width: 100%;

    &-item {
      &-description {
        font-size: $fontSize-xsm;
        color: $color-metallic-silver;
      }

      &-value {
        width: 62px;
        height: 30px;
        padding: 4px 12px;
        border: 1px solid rgba(134, 143, 160, 0.16);
        border-radius: 6px;
      }
    }
  }
}
