.active-baskets-list {
  padding: 40px;
  width: 100%;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }

  &__container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }

  &__filter {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &-item {
      &-title {
        margin-bottom: 8px;
        font-family: $fontFamily-Roboto-Medium;
      }

      &-select {
        & .ant-select-arrow {
          transform: rotate(0);
        }
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .active-baskets-list {
    &__container {
      flex-direction: column;
    }

    &__content {
      width: 100%;
    }
  }
}

@media (max-width: $screen-maxWidth-md) {
  .active-baskets-list {
    &__filter  {
      width: 100%;
    }
  }
}
