.delivery-for-contract {
  padding: 40px;
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    & * {
      font-size: $fontSize-lg;
      text-transform: uppercase;
    }

    &-prefix {
      color: $color-metallic-silver;
    }
  }

  &__container {
    width: 100%;
  }
}

.delivery-city {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-lavender;

  &__status {
    width: 16px;
    height: 16px;
    margin-right: 12px;

    & path {
      fill: $color-roman-silver;
    }
  }

  &__name {
    flex: 1;

    & *:not(&-prefix) {
      text-transform: uppercase;
    }

    &-prefix {
      color: $color-metallic-silver;
    }
  }
}

.stock-city {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-lavender;

  & .ant-select {
    max-width: 131px;
  }

  &__status {
    width: 16px;
    height: 16px;
    margin-right: 28px;

    & path {
      fill: $color-roman-silver;
    }
  }

  &__name {
    flex: 1;

    &-prefix {
      color: $color-metallic-silver;
    }
  }

  &__close {
    margin-left: 10px;
  }
}
