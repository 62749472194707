.shop-search {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  gap: 10px;

  &__dropdown {
    padding: 10px;

    &-block {
      margin-bottom: 12px;
    }

    &-title {
      margin-bottom: 12px;
      color: $color-roman-silver;
    }

    &-item {
      width: 100%;
      max-width: 572px;
      display: block;
      border: none;
      box-shadow: none;
      text-align: left;
      color: $color-black !important;

      & .ant-wave {
        display: none;
      }

      &:hover {
        background-color: $color-lavender;
      }

      & span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-table {
      display: grid;
      grid-template-columns: 1fr 1fr;

      & .shop-search__dropdown-item {
        max-width: 286px;
      }
    }
  }
}
