.redesign.preset {
  min-height: 36px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  background-color: $color-hover-light-grey;
  border: none;
  border-radius: 36px;

  &.is-required {
    background-color: #e8f5ff;
  }

  &.is-category {
    background-color: #e8eaff;
  }

  &.is-clear-all {
    background-color: $color-background-pale-red;
  }

  & .button-icon {
    margin-right: -8px;
  }
}

.preset-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
