.qrfp-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;

  &__menu {
    width: 239px;
    border: none !important;

    & .ant-menu-item {
      border-radius: 0;

      &:hover {
        background-color: $color-bright-gray !important;
      }

      &-selected {
        background-color: $color-lavender !important;
        color: initial;

        &:hover {
          background-color: $color-lavender !important;
        }
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    & .ant-table-row {
      cursor: pointer;
    }

    &-item {
      &-status {
        width: max-content;
        padding: 2px 8px;
        border-radius: 4px;

        &-wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        & span {
          display: flex;
          align-items: center;
          font-size: $fontSize-xsm;
  
          &::before {
            content: '';
            width: 6px;
            height: 6px;
            margin-right: 6px;
            display: inline-block;
            border-radius: 2px;
          }
        }

        &.new {
          background: $color-lavender;
          color: $color-majorelle-blue;
  
          & span::before {
            background: $color-majorelle-blue;
          }
        }
  
        &.completed {
          background: $color-bright-gray;
          color: $color-dark-electric-blue;
  
          & span::before {
            background: $color-dark-electric-blue;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .qrfp-list {
    flex-direction: column;

    &__content {
      width: 100%;
    }
  }
}
