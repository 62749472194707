.three-dot-menu {
  width: 32px;

  & .ant-btn-compact-first-item {
    display: none;
  }

  & .ant-btn-compact-last-item {
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    & .ant-wave {
      display: none;
    }
  }

  &__dropdown {
    & .ant-dropdown-menu-item {
      padding: 0 !important;

      & .ant-btn {
        width: 100%;
        height: 100%;
        padding: 4px 16px;
        background: transparent;
        border: none;
        box-shadow: none;
        text-align: start;
    
        & .ant-wave {
          display: none;
        }
  
        &:hover {
          color: initial !important;
        }
      }
    
      &.login .ant-btn {
        color: $color-majorelle-blue !important;
    
        &:hover {
          color: $color-majorelle-blue !important;
        }
      }
      
      &.edit .ant-btn {
        color: $color-independence !important;
    
        &:hover {
          color: $color-independence !important;
        }
      }
    
      &.block .ant-btn {
        color: $color-windsor-tan !important;
    
        &:hover {
          color: $color-windsor-tan !important;
        }
      }
    
      &.delete .ant-btn {
        color: $color-red !important;
    
        &:hover {
          color: $color-red !important;
        }
      }

      &.createSeller .ant-btn {
        color: $color-islamic-green !important;
    
        &:hover {
          color: $color-islamic-green !important;
        }
      }

      & .ant-btn:disabled {
        background: $color-ghost-white !important;
        color: $color-roman-silver !important;

        &:hover {
          color: $color-roman-silver !important;
        }
      }
    }
  }
}
