.navigation {
  display: flex;
  align-items: baseline;
  gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5) !important;
    text-decoration: none;

    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-dot {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      font-size: $fontSize-lg;
      color: $color-black !important;
      text-transform: uppercase;
    }
  }
}
