@font-face {
  font-family: 'Roboto-Light';
  src: url('../../fonts/Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../fonts/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Onest-Regular';
  src: url('../../fonts/Onest-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Onest-Medium';
  src: url('../../fonts/Onest-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Onest-SemiBold';
  src: url('../../fonts/Onest-SemiBold.ttf');
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: 'Onest-Bold';
  src: url('../../fonts/Onest-Bold.ttf');
  font-weight: 700;
  font-style: normal
}

body {
  & * {
    font-family: $fontFamily-Roboto-Regular;
    font-size: $fontSize-sm;
  }
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}