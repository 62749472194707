.goods-counter {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;

  & .ant-btn {
    width: auto;
    padding: 0 4px;
    display: flex;
    align-items: center;
    border-color: rgba(134, 143, 160, 0.16);

    & svg {
      width: 8px;
      height: 8px;
    }

    &:hover {
      border-color: rgba(134, 143, 160, 0.4) !important;
      color: initial !important;
    }

    & .ant-wave {
      --wave-color: #4945c4 !important;
    }

    &:disabled {
      background: $color-ghost-white;
    }
  }

  &__decrement {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__increment {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__input {
    border-left: none;
    border-right: none;
    border-radius: 0;
    z-index: 1;
    box-shadow: none !important;


    & .ant-input-number-input {
      padding: 4px 0 !important;
      text-align: center;
    }
  }
}