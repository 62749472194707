.spin-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &-spin {
    width: 48px;
    height: 48px;
    display: block;
    position: relative;
    background-image: radial-gradient(circle at center, #79b851 5px, transparent 0),
      radial-gradient(circle at center, #f8d247 5px, transparent 0), radial-gradient(circle at center, #dddf4a 5px, transparent 0),
      radial-gradient(circle at center, #fbec4e 5px, transparent 0);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position:
      left top,
      left bottom,
      right top,
      right bottom;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  0% {
    width: 48px;
    height: 48px;
    transform: rotate(0deg);
  }
  50% {
    width: 26px;
    height: 26px;
    transform: rotate(180deg);
  }
  100% {
    width: 48px;
    height: 48px;
    transform: rotate(360deg);
  }
}
