.goods-delivery-time {
  display: flex;
  align-items: center;
  gap: 6px;

  &__time {
    min-width: 42px;
  }

  &__tooltip {
    & .ant-table {
      background: transparent;
  
      &-row:hover {
        & .ant-table-cell {
          background: transparent;
        }
      }
  
      &-cell {
        color: $color-white;
        border-bottom: none !important;
      }

      &-placeholder {
        background: transparent !important;

        &:hover .ant-table-cell {
          background: transparent !important;
        }
      }
    }
  
    &-item{
      &-days {
        display: flex;
        align-items: center;
        gap: 10px;
  
        & svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}