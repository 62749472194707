@import '../../abstracts/variables';
.redesign {
  .ant-dropdown {
    &-menu {
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: $color-general-white;
      border-radius: 22px;
      border: 1px solid $color-outline-grey;
      box-shadow: 0 0 24px 12px rgb(41, 62, 53, 0.06);

      &-item {
        padding: 8px !important;
        border-radius: 8px !important;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0px;
          height: 0px;
          background: $color-background-dark-grey;
          border-radius: 50%;
          transform: translate(-50%, -50%) scale(0);
        }

        &:active::before {
          width: 300px;
          height: 300px;
          animation: ripple 0.4s ease-out;
        }

        &:hover {
          background-color: $color-hover-light-grey !important;
        }

        &-selected {
          background-color: transparent !important;

          & .ant-dropdown-menu-title-content {
            font-family: $ff-Onest-Medium !important;
          }
        }

        &-divider {
          margin: 8px 0 !important;
          background-color: $color-outline-invisible-grey !important;
        }

        &-disabled {
          background-color: $color-hover-light-grey;

          & .ant-dropdown-menu-title-content {
            color: $color-outline-grey !important;
          }

          &::before {
            display: none;
          }
        }

        &.red {
          & .ant-dropdown-menu-title-content {
            color: $color-general-red;
          }
        }
      }

      &-title-content {
        font-family: $ff-Onest-Regular !important;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
        color: $color-general-black;
        z-index: 1;
      }
    }
  }

  .ant-breadcrumb {
    line-height: 0;

    & ol {
      align-items: center;
    }

    & .ant-breadcrumb {
      &-link {
        font-family: $ff-Onest-Medium;
        font-size: $fs-s;
        text-decoration: none;
        color: $color-general-bright-green;
        cursor: pointer;
      }

      &-separator {
        margin: 0 4px;
      }
    }

    & li:last-child .ant-breadcrumb-link {
      font-family: $ff-Onest-Regular;
      color: $color-general-dark-grey;
      cursor: default;
    }

    & svg {
      width: 16px;
    }
  }

  .ant-select {
    width: 100%;
    min-height: 44px;
    border: 1px solid $color-outline-grey !important;
    border-radius: 44px;
    box-shadow: none !important;

    &:hover {
      border-color: $color-outline-dark-grey-hover !important;
    }

    &-open {
      border-color: $color-general-bright-green !important;

      &:hover {
        border-color: $color-general-bright-green !important;
      }
    }

    &-selector {
      padding: 0 20px !important;
      width: 100%;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    &-selection-item {
      font-family: $ff-Onest-Medium;
      font-size: $fs-s;
      text-decoration: none;
    }

    &-arrow {
      inset-inline-end: 20px;
      transform: none;
    }

    &:hover {
      & .ant-select-selector {
        border-color: transparent !important;
      }
    }

    &-selection-placeholder {
      padding-left: 10px;
      font-family: $ff-Onest-Regular;
      font-size: $fs-s;
      color: $color-general-dark-grey;
      line-height: 140% !important;
    }

    &-status-error {
      border-color: $color-background-red !important;

      &:hover {
        border-color: $color-background-red !important;
      }
    }

    &-item-group {
      margin-bottom: 20px;
      font-family: $ff-Onest-Medium;
      font-size: $fs-xs;
      color: $color-general-bright-green;
      pointer-events: none;

      &:not(&:first-child) {
        margin-top: 20px;
      }
    }

    &-item {
      padding: 8px;
      border-radius: 8px;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0px;
        height: 0px;
        background: $color-background-dark-grey;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
      }

      &:active::before {
        width: 300px;
        height: 300px;
        animation: ripple 0.4s ease-out;
      }

      &-option-content {
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
        white-space: normal;
        z-index: 1;
      }

      &:hover {
        background-color: $color-hover-light-grey !important;
      }

      &-option-selected {
        background-color: transparent !important;

        & .ant-select-item-option-content {
          font-family: $ff-Onest-Medium;
        }

        &:hover {
          background-color: $color-hover-light-grey !important;
        }
      }

      &-option[type='divider'] {
        margin: 8px 0;
        padding: 0;
        min-height: 1px;
        background-color: $color-outline-invisible-grey;
        border-radius: 0;
        cursor: default;
      }

      &-empty {
        padding: 8px;
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
        color: $color-general-dark-grey;
      }
    }

    &-disabled {
      background-color: $color-hover-light-grey;

      &:hover {
        border-color: $color-outline-grey !important;
      }

      & .ant-select {
        &-selection-placeholder {
          color: $color-outline-grey;
        }

        &-selection-overflow-item {
          color: $color-outline-grey;
        }

        &-arrow {
          & path {
            stroke: $color-outline-grey;
          }
        }
      }
    }
  }

  .ant-select-multiple {
    & .ant-select {
      &-selector {
        padding: 0 42px 0px 20px !important;
        min-height: 42px;
      }

      &-selection-search {
        margin-inline-start: 0;
      }

      &-selection-overflow {
        overflow: hidden;
        flex-wrap: nowrap;

        &-item {
          & span {
            font-family: $ff-Onest-Medium;
            font-size: $fs-s;
          }

          &:not(.ant-select-selection-overflow-item-rest) {
            height: auto !important;
            opacity: 1 !important;
            position: relative !important;
          }

          &:after {
            content: ',';
            display: block;
            margin-right: 4px;
            font-family: $ff-Onest-Medium;
            font-size: $fs-s;
          }

          &-rest {
            position: absolute !important;
            right: 0;
            background-color: $color-general-white;
            z-index: 1;

            &::after {
              display: none !important;
            }

            & .ant-select-selection-item {
              margin: 0;
              height: auto;
              background-color: $color-general-white;
            }
          }

          &-suffix {
            display: none !important;
          }
        }
      }
    }
  }

  &.ant-select-dropdown {
    padding: 20px;
    border: 1px solid $color-outline-grey;
    border-radius: 22px;
    box-shadow: 0 0 24px 12px rgb(41, 62, 53, 0.06);
  }

  .rc-virtual-list-holder {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-general-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-outline-grey;
      border-radius: 20px;
      border: 3px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: $color-outline-dark-grey-hover;
      }
    }
  }

  .ant-table {
    width: 100%;

    &-cell {
      border-top: 1px solid transparent;
    }

    &-thead {
      & th {
        background-color: $color-general-white !important;
        border-radius: 0 !important;
      }

      & .ant-table-cell {
        padding: 8px 20px;
        border-bottom: 1px solid $color-outline-grey !important;
        font-family: $ff-Onest-Regular;
        font-size: $fs-xs;
        color: $color-general-dark-grey !important;
        white-space: nowrap;

        &::before {
          display: none;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }

    &-row {
      & .ant-table-cell {
        padding: 20px;
        background-color: transparent !important;
        border-bottom: 1px solid $color-outline-light-grey !important;
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 140%;
        text-decoration: none;
        vertical-align: top;

        &:first-child {
          padding-left: 0 !important;
        }

        &:last-child {
          padding-right: 0 !important;
          text-align: right;
          white-space: nowrap;
        }
      }
    }

    &-placeholder {
      & .ant-table-cell {
        padding: 0 !important;
        border-bottom: none !important;
      }
    }

    &-selection-column {
      vertical-align: middle !important;

      & .ant-checkbox {
        padding: 0 !important;
      }
    }
  }

  .ant-input {
    padding: 0 20px;
    width: 100%;
    height: 44px;
    border: 1px solid $color-outline-grey;
    border-radius: 44px;
    font-family: $ff-Onest-Medium;
    font-size: $fs-s;
    text-decoration: none;
    box-shadow: none !important;

    &::placeholder {
      font-family: $ff-Onest-Regular;
      color: $color-general-dark-grey;
    }

    &:hover {
      border-color: $color-outline-dark-grey-hover;
    }

    &:focus {
      border-color: $color-general-bright-green;
    }

    &:disabled {
      background-color: $color-hover-light-grey !important;
      color: $color-outline-grey;
    }

    &-status-error {
      border-color: $color-background-red !important;
    }

    &-affix-wrapper {
      padding: 0 20px;
      width: 100%;
      height: 44px;
      border: 1px solid $color-outline-grey;
      border-radius: 44px;
      box-shadow: none !important;

      & .ant-input {
        height: 100%;
        font-family: $ff-Onest-Medium;
        font-size: $fs-s;

        &-suffix {
          margin: 0;
        }

        &-clear-icon {
          margin: 0;
          display: flex;
          align-items: center;
        }

        &-password-icon {
          & rect {
            fill: transparent;
          }

          & path {
            fill: $color-general-dark-grey;
          }
        }
      }

      &:hover {
        border-color: $color-outline-dark-grey-hover !important;
      }

      &:focus-within {
        border-color: $color-general-bright-green !important;
      }

      &-disabled {
        background-color: $color-hover-light-grey;
        color: $color-outline-grey;
      }

      &-status-error {
        border-color: $color-background-red !important;

        &:hover {
          border-color: $color-background-red !important;
        }

        &:focus-within {
          border-color: $color-background-red !important;
        }
      }
    }
  }

  .ant-input-number {
    padding: 0 20px;
    width: 100%;
    height: 44px;
    border: 1px solid $color-outline-grey;
    border-radius: 44px;
    box-shadow: none;

    &-wrapper {
      box-shadow: none !important;
    }

    &-input-wrap {
      height: 100%;
    }

    &-input {
      padding: 0 !important;
      height: 100%;
      font-family: $ff-Onest-Medium !important;
      font-size: $fs-s;
      text-decoration: none;

      &::placeholder {
        font-family: $ff-Onest-Regular;
        color: $color-general-dark-grey;
      }
    }

    &-suffix {
      margin-right: 20px;
      background-color: $color-general-white;
      font-family: $ff-Onest-Regular;
      font-size: $fs-s;
      color: $color-general-dark-grey;
    }

    &:hover {
      border-color: $color-outline-dark-grey-hover;
    }

    &-focused {
      border-color: $color-general-bright-green !important;
    }

    &-affix-wrapper {
      padding: 0 20px;
      width: 100%;
      height: 44px;
      border: 1px solid $color-outline-grey;
      border-radius: 44px;
      box-shadow: none;

      & .ant-input-number {
        padding: 0;
        height: 100%;

        &-prefix {
          margin-right: 12px;
          padding: 0;
          font-family: $ff-Onest-Regular;
          font-size: $fs-s;
          text-decoration: none;
          color: $color-general-dark-grey !important;
        }

        &-input {
          padding: 0;
        }
      }

      &:hover {
        border-color: $color-outline-dark-grey-hover;
      }

      &:focus-within {
        border-color: $color-general-bright-green !important;

        & .ant-input-number-prefix {
          color: $color-general-bright-green !important;
        }
      }

      &-disabled {
        background-color: $color-hover-light-grey !important;

        & .ant-input-number-input {
          background-color: $color-hover-light-grey;
          color: $color-outline-grey;
        }

        & .ant-input-number-suffix {
          background-color: $color-hover-light-grey;
        }
      }

      &-status-error {
        border-color: $color-background-red !important;

        &:focus-within {
          border-color: $color-background-red !important;
        }

        & .ant-input-number-suffix {
          color: initial !important;
        }
      }
    }
  }

  .ant-checkbox {
    &-wrapper {
      & span:not(.ant-checkbox span):last-child {
        padding: 0 16px;
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
      }

      & .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 1.25px solid $color-general-dark-grey;
        border-radius: 5px;

        &::after {
          height: 10.63px;
          width: 6.88px;
        }
      }

      & .ant-checkbox-indeterminate {
        & .ant-checkbox-inner {
          background-color: $color-general-dark-green;
          border-color: $color-general-dark-green !important;

          &:after {
            width: 10.62px;
            height: 1.67px;
            background-color: $color-general-white;
            transform: translate(-50%, -50%) !important;
          }
        }
      }

      &:hover {
        & .ant-checkbox-inner {
          background-color: $color-hover-light-grey;
          border: 1.25px solid $color-general-dark-grey !important;

          &:after {
            border-color: $color-outline-grey;
            opacity: 1;
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
          }
        }

        & .ant-checkbox-indeterminate {
          & .ant-checkbox-inner {
            background-color: $color-hover-dark-green;
            border-color: $color-hover-dark-green !important;
          }
        }
      }

      &:active {
        & .ant-checkbox-inner {
          background-color: $color-pressed-light-grey;
        }

        & .ant-checkbox-indeterminate {
          & .ant-checkbox-inner {
            background-color: $color-pressed-dark-green;
            border-color: $color-pressed-dark-green !important;
          }
        }
      }

      &.ant-checkbox-wrapper-disabled {
        & .ant-checkbox-inner {
          border-color: $color-outline-grey;
          background-color: $color-hover-light-grey;

          &:after {
            display: none;
          }
        }

        & .ant-checkbox-indeterminate {
          & .ant-checkbox-inner {
            border-color: $color-outline-grey !important;
            background-color: $color-hover-light-grey !important;
          }
        }
      }
    }

    &-wrapper-checked {
      & .ant-checkbox-inner {
        background-color: $color-general-dark-green;
        border-color: $color-general-dark-green !important;
      }

      &:hover {
        & .ant-checkbox-inner {
          background-color: $color-hover-dark-green !important;
          border-color: $color-hover-dark-green !important;

          &:after {
            border-color: $color-general-white;
          }
        }
      }

      &:active {
        & .ant-checkbox-inner {
          background-color: $color-pressed-dark-green !important;
          border-color: $color-pressed-dark-green !important;
        }
      }

      &.ant-checkbox-wrapper-disabled {
        & .ant-checkbox-inner {
          background-color: $color-hover-light-grey !important;
          border-color: $color-hover-light-grey !important;

          &:after {
            display: block;
            border-color: $color-outline-grey;
          }
        }
      }
    }
  }

  .ant-radio {
    &-wrapper {
      & span:not(.ant-radio span):last-child {
        padding: 0 16px;
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
      }

      & .ant-radio-inner {
        width: 20px;
        height: 20px;
        border: 1px solid $color-general-dark-grey;

        &:after {
          margin: 0;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      &:hover {
        & .ant-radio-inner {
          background-color: $color-hover-light-grey;

          &:after {
            background-color: $color-outline-grey;
            opacity: 1;
            transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          }
        }
      }

      &:active {
        & .ant-radio-inner {
          background-color: $color-pressed-light-grey;
        }
      }
    }

    &-wrapper-checked {
      & .ant-radio-inner {
        background-color: $color-general-white;
        border-color: $color-general-dark-green;

        &:after {
          background-color: $color-general-dark-green;
        }
      }

      &:hover {
        & .ant-radio-inner {
          border-color: $color-hover-dark-green;

          &:after {
            background-color: $color-hover-dark-green;
          }
        }
      }

      &:active {
        & .ant-radio-inner {
          border-color: $color-pressed-dark-green;

          &:after {
            background-color: $color-pressed-dark-green;
          }
        }
      }
    }

    &:hover::after {
      border-color: $color-hover-dark-green;
    }
  }

  .ant-badge {
    &-dot {
      width: 10px;
      height: 10px;
    }
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }

  .ant-menu {
    margin-bottom: 32px;
    border-bottom: 1px solid $color-outline-grey;
    line-height: 34px;

    &-item {
      padding: 0;
      margin-right: 44px;

      & .ant-menu-title-content {
        font-family: $ff-Onest-Regular;
        font-size: $fs-m;
        line-height: 130%;
        text-decoration: none;
        color: $color-general-dark-grey;
      }

      &::after {
        inset-inline: 0 !important;
      }

      &-active {
        &::after {
          border-bottom-color: $color-general-black !important;
        }
      }

      &-selected {
        & .ant-menu-title-content {
          font-family: $ff-Onest-Medium;
          font-size: $fs-m;
          line-height: 120%;
          text-decoration: none;
          color: $color-general-black;
        }

        &::after {
          border-bottom-color: $color-general-black !important;
        }
      }
    }
  }
}

.ant-drawer {
  &-mask {
    background: $color-overlay-light-overlay !important;
  }

  &-header {
    padding: 40px !important;
    border: none !important;

    &-title {
      justify-content: flex-end;
    }
  }

  &-close {
    margin: 0;
    width: 44px;
    height: 44px;
    background-color: $color-hover-light-grey !important;
    border-radius: 50%;

    & path {
      fill: $color-general-black;
    }
  }

  &-body {
    padding: 12px 80px 0px !important;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-content {
    border-left: 1px solid $color-outline-grey;
  }
}

.ant-wave {
  display: none;
}

.ant-tooltip {
  max-width: max-content;

  &-inner {
    padding: 16px;
    font-family: $ff-Onest-Regular;
    font-size: $fs-xs;
    text-decoration: none;
  }
}

.ant-message {
  top: unset !important;
  bottom: 40px;
  z-index: 100000;

  &-notice-content {
    padding: 16px !important;
    width: 100%;
    max-width: 1000px;
    background-color: $color-background-black !important;
  }

  &-custom-content {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    & * {
      font-family: $ff-Onest-Regular !important;
      font-size: $fs-xs !important;
      text-decoration: none;
      color: $color-general-white !important;
    }

    & span {
      flex: 1;
      text-align: start;
    }
  }
}

.ant-spin {
  &-nested-loading {
    & .ant-spin {
      max-height: none !important;
    }
  }
}

.ant-picker {
  padding: 0 20px;
  width: 100%;
  height: 44px;
  border: 1px solid $color-outline-grey;
  border-radius: 44px;

  &-dropdown {
    width: 420px;
  }

  & input {
    font-family: $ff-Onest-Medium !important;
    font-size: $fs-s !important;

    &::placeholder {
      font-family: $ff-Onest-Regular;
      color: $color-general-dark-grey;
    }
  }

  &:hover {
    border-color: $color-outline-dark-grey-hover;

    & .ant-picker-suffix {
      & path {
        stroke: $color-general-black;
      }
    }
  }

  &-focused {
    border-color: $color-general-bright-green !important;
    box-shadow: none !important;
  }

  &-panel-container {
    border: 1px solid $color-outline-grey;
    border-radius: 22px !important;
    box-shadow: 0 0 24px 12px rgba(41, 62, 53, 0.06) !important;
  }

  &-panel-layout {
    width: 100%;
  }

  &-panel {
    width: 100%;
    border-top: none !important;
  }

  &-date-panel {
    width: 100% !important;
  }

  &-header {
    padding: 29px 28px 12px !important;
    align-items: center;
    border-bottom: none !important;

    &-super-prev-btn {
      display: none;
    }

    &-super-next-btn {
      display: none;
    }

    &-prev-btn,
    &-next-btn {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      border-radius: 50%;

      &:hover {
        background-color: $color-hover-light-grey !important;
      }

      &:active {
        background-color: $color-pressed-light-grey !important;
      }
    }

    &-view * {
      font-family: $ff-Onest-Medium;
      font-size: $fs-m !important;
      pointer-events: none;
    }
  }

  &-month-btn {
    text-transform: capitalize;
  }

  &-body {
    padding: 0 0 40px !important;
    display: flex;
    justify-content: center;
  }

  &-cell {
    padding: 0 !important;
    width: 44px !important;
    height: 44px !important;
    border-radius: 44px;

    & .ant-picker-cell-inner {
      background-color: transparent !important;
      border: none !important;
      font-family: $ff-Onest-Medium;
      font-size: $fs-s;
      color: $color-outline-grey;

      &::before {
        border: none !important;
      }
    }

    &:hover {
      background-color: $color-hover-light-grey;

      & .ant-picker-cell-inner {
        background-color: transparent !important;
        color: $color-general-black;
      }
    }
  }

  &-cell-in-view {
    & .ant-picker-cell-inner {
      color: $color-general-black;
    }

    &:hover {
      background-color: $color-background-pale-green;

      & .ant-picker-cell-inner {
        color: $color-general-bright-green !important;
      }
    }
  }

  &-cell-selected {
    background-color: $color-hover-light-bright-green;

    & .ant-picker-cell-inner {
      color: $color-general-white !important;
    }

    &:hover {
      background-color: $color-hover-light-bright-green;

      & .ant-picker-cell-inner {
        color: $color-general-white !important;
      }
    }
  }

  &-cell-disabled {
    & .ant-picker-cell-inner {
      background-color: transparent !important;
      color: $color-outline-grey !important;
    }

    &::before {
      background-color: transparent !important;
    }
  }

  &-content {
    border-collapse: separate !important;
    border-spacing: 6px 0;

    & th {
      width: 44px !important;
      height: 44px !important;
      font-family: $ff-Onest-Medium;
      font-size: $fs-s;
      text-transform: capitalize;
      color: $color-general-black !important;

      &:nth-child(6),
      &:nth-child(7) {
        color: $color-general-dark-grey !important;
      }
    }

    & tr td {
      &:nth-child(6).ant-picker-cell-in-view {
        & .ant-picker-cell-inner {
          color: $color-general-dark-grey;
        }
      }
      &:nth-child(7).ant-picker-cell-in-view {
        & .ant-picker-cell-inner {
          color: $color-general-dark-grey;
        }
      }
    }
  }

  &-footer {
    padding: 36px 40px 40px;
    border-top: none !important;
  }
}

.ant-popover {
  &-arrow {
    display: none !important;
  }

  &-inner {
    padding: 28px !important;
    border: 1px solid $color-outline-grey;
    border-radius: 22px !important;
    box-shadow: 0 0 24px 12px rgba(41, 62, 53, 0.06) !important;
  }
}

.ant-switch {
  min-width: 33px;
  height: 20px;

  &-handle {
    width: 16px !important;
    height: 16px !important;
  }

  &-checked {
    background-color: $color-general-dark-green !important;

    & .ant-switch-handle {
      inset-inline-start: calc(100% - 18px) !important;
    }
  }
}

.ant-form {
  &-item {
    &-label {
      & label {
        font-family: $ff-Onest-Regular;
        text-decoration: none;
      }
    }

    &:focus-within {
      & .ant-form-item-label label {
        color: $color-general-bright-green;
      }
    }

    &-explain-error {
      font-family: $ff-Onest-Regular;
      font-size: $fs-xs;
      color: $color-general-red !important;
    }
  }
}

@keyframes ripple {
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
