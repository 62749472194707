.subdivision-addresses {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__table {
    &_item {
      &-icon {
        width: 20px;
        height: 20px;
        background-image: url(../../images/truck-white.svg);

        &.isMain {
          background-image: url(../../images/truck-black.svg);
        }
      }

      &:hover &-icon{
        background-image: url(../../images/truck-black.svg);
      }

      &-tag {
        padding: 2px 6px;
        background: $color-majorelle-blue;
        border-radius: 6px;
        color: $color-white;
      }
    }
  }
}