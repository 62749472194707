.user-card {
  width: 100%;
  max-width: 680px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 47px;
  }

  &__header {
    display: flex;
    gap: 22px;

    &_user-name-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &_user-name {
      font-size: $fontSize-lg;
      line-height: 34px;
      word-wrap: break-word;
    }

    &_user-email {
      font-size: $fontSize-lg;
      color: $color-majorelle-blue;
    }

    &-close {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 27px;

    &_item {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__footer {
    margin-top: -20px;
  }
}

@media (max-width: $screen-maxWidth-xxl) {
  .user-card {
    max-width: 422px;

    &__header {
      gap: 10px;

      &_user-name-container {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0;
      }

      &_user-email {
        font-size: $fontSize-md;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .user-card {
    max-width: 100%;
  }
}
