.name-label {
  width: 100%;
  max-width: max-content;
  display: inline-flex;
  align-items: center;
  gap: 12px;

  &__icon {
    min-width: 32px;
    min-height: 32px;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-background-pale-green;
    border-radius: 6px;
    
  }

  &__name {
    flex: 1;
  }
}
