.position-search {
  &__input.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  &__dropdown {
    &-popup {
      height: 421px;
      display: flex;
      gap: 10px;
    }

    &-container {
      height: 100%;
      padding: 20px;
      border-radius: 6px;
      box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.25), 0px 4px 10px 0px rgba(0, 0, 0, 0.25), 4px 0px 10px 0px rgba(0, 0, 0, 0.25);
      overflow: scroll;
      scrollbar-color: $color-dark-electric-blue transparent;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &-title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: $fontFamily-Roboto-Light;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }

      &-select-icon {
        width: 26px;
      }
    }

    &-search {
      margin-bottom: 20px;
    }
  }
}
