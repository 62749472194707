.form-input-range {
  display: flex;
  flex-direction: column;

  & .ant-form-item {
    max-width: 150px;
    flex: 1;
  }

  &__label {
    padding-bottom: 8px;
  }

  &__container {
    display: flex;
    align-items: flex-end;
  }

  &__from {
    border-right: none;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    transition: none;

    & .ant-input-number-input {
      padding: 4px 24px 4px 12px !important;
    }
  }

  &__to-suffix {
    border-left: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    transition: none;

    & .ant-input-number-input {
      padding: 4px 34px 4px 0px !important;
    }
  }

  &__to {
    border-left: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    transition: none;

    & .ant-input-number-input {
      padding: 4px 12px 4px 0px !important;
    }
  }

  &:hover {
    & .form-input-range__from {
      border-color: rgba(134, 143, 160, 0.4) !important;
    }

    & .form-input-range__to-suffix {
      border-color: rgba(134, 143, 160, 0.4) !important;
    }

    & .form-input-range__to {
      border-color: rgba(134, 143, 160, 0.4) !important;
    }
  }

  &:focus-within {
    & .form-input-range__from {
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
      clip-path: inset(-4px 0px -4px -4px);

      &.ant-input-number-affix-wrapper-status-error {
        border-color: rgba(134, 143, 160, 0.4) !important;
        box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4) !important;
        clip-path: inset(-4px 0px -4px -4px);
      }
    }

    & .form-input-range__to-suffix {
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4);
      clip-path: inset(-4px -4px -4px 0px);

      &.ant-input-number-affix-wrapper-status-error {
        border-color: rgba(134, 143, 160, 0.4) !important;
        box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4) !important;
        clip-path: inset(-4px -4px -4px 0px);
      }
    }

    & .form-input-range__to {
      border-color: rgba(134, 143, 160, 0.4) !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4) !important;
      clip-path: inset(-4px -4px -4px 0px);

      &.ant-input-number-affix-wrapper-status-error {
        border-color: rgba(134, 143, 160, 0.4) !important;
        box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.4) !important;
        clip-path: inset(-4px -4px -4px 0px);
      }
    }
  }

  & .ant-input-number-suffix {
    color: $color-metallic-silver;
  }

  & .ant-input-number-disabled {
    background: rgba(0, 0, 0, 0.04) !important;
  }
}