.property-form {
  width: 419px;
  height: 100%;
  padding-left: 34px;
  display: flex;
  flex-direction: column;
  background: $color-white;

  & .ant-form-item {
    margin-bottom: 30px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-tabs {
      width: min-content;
    }

    &-item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-lavender;
    
      & .ant-form-item {
        margin-bottom: 0;
      }

      & .ant-input-affix-wrapper {
        width: 130px;
      }

      & .ant-select {
        width: max-content;
      }
    }
  }

  &__list-of-values {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .property-form {
    width: 100%;
  }
}
