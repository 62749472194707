.goods-card {
  &__header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__labels-container {
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__price {
    margin-bottom: 52px;
  }

  &__actions-container {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }

  &__btn {
    margin-bottom: 72px;
    display: flex;
  }

  &__block {
    margin-bottom: 72px;

    &-title {
      padding-bottom: 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 40px;

      &-row {
        display: contents;
      }
    }
  }
}

.goods-card-min {
  margin-bottom: 32px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  background-color: $color-hover-light-grey;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  &__name {
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}
