.goods-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-family: $fontFamily-Roboto-Bold;
    font-size: $fontSize-md;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }

  &__table {
    &-wrapper {
      padding-top: 15px;
      border-top: 1px dotted rgba(0, 0, 0, 0.4);
    }

    &-title {
      margin-bottom: 12px;
      font-family: $fontFamily-Roboto-Bold;
      font-size: $fontSize-md;
    }

    & .ant-table {
      &-thead {
        & .ant-table-cell {
          padding: 0 16px;
          background: transparent;
          border-color: rgba(0, 0, 0, 0.4);
          font-family: $fontFamily-Roboto-Bold;
          font-size: 10px;

          &::before {
            display: none;
          }
        }
      }

      &-tbody {
        & .ant-table-cell {
          font-size: $fontSize-xsm;
        }
      }
    }

    &-item {
      &-basket {
        padding: 0 !important;

        & div {
          display: flex;
          align-items: center;
        }
      }

      &-name {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-price {
        font-family: $fontFamily-Roboto-Bold;
        color: $color-brandeis-blue;
        text-wrap: nowrap;
      }
    }
  }

  &__empty-message {
    padding: 70px 0px;
  }
}