.warning-drawer {
  & .ant-drawer {
    &-wrapper-body {
      background-color: $color-background-pale-red;
    }

    &-body {
      display: flex;
      flex-direction: column;
    }
  }
}
