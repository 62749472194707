.property-cascader {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  &__scroll-container {
    position: relative;
    flex: 1;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    display: flex;
    gap: 30px;
    height: 100%;
  }
}

.property-tree {
  width: 419px;
  height: 100%;
  padding-right: 34px;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: $color-white;

  &__title {
    padding: 4px;
    font-family: $fontFamily-Roboto-Bold;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      background-color: $color-lavender;
    }

    &.selected {
      background-color: $color-lavender;
    }
  }

  &__subtitle {
    font-family: $fontFamily-Roboto-Bold;
    text-transform: uppercase;
  }

  &__header {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .three-dot-menu {
      display: none;
    }

    &:hover .three-dot-menu {
      display: block;
    }
  }

  &__scroll-container {
    position: relative;
    height: 100%;
    overflow: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  &__item-name {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .ant-tree {
    min-width: 100%;
    position: absolute;

    &-title {
      .root {
        font-family: $fontFamily-Roboto-Bold;
      }

      & .three-dot-menu {
        opacity: 0;
      }

      & .btn-icon {
        display: none;
      }

      &:hover {
        & .three-dot-menu {
          opacity: 1;
        }

        & .btn-icon {
          display: block;
        }
      }
    }
  }
}

.property-cascader-item {
  position: relative;
  width: 385px;
  height: 100%;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    padding: 4px;
    font-family: $fontFamily-Roboto-Bold;
    text-transform: uppercase;
  }

  &__message {
    position: absolute;
    top: 92px;
    left: 0;
    font-family: $fontFamily-Roboto-Light;
    font-size: $fontSize-xsm;
    color: $color-red;
  }

  &__container {
    overflow-x: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  &__search-property-dropdown {
    & .ant-select-item-option {
      position: relative;

      &::after {
        content: 'привязать';
        display: block;
        font-family: Arial, Helvetica, sans-serif;
        color: $color-majorelle-blue;
      }
    }
  }

  &__property {
    margin-bottom: 6px;
    min-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-name {
      flex: auto;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-name::before {
      content: '';
      width: 10px;
      height: 10px;
      margin-left: 6px;
      margin-right: 10px;
      display: inline-block;
      background-color: $color-islamic-green;
      border-radius: 2px;
    }

    &-type {
      color: $color-majorelle-blue;
    }

    &.selected {
      background-color: $color-lavender;
    }

    & .three-dot-menu {
      display: none;
    }

    & .btn-icon {
      display: none;
    }

    &:hover {
      background-color: $color-lavender;

      & .three-dot-menu {
        display: flex;
      }

      & .btn-icon {
        display: block;
      }
    }

    &.is-category {
      margin-bottom: 28px;
    }
  }

  &__option {
    margin-bottom: 6px;
    min-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-name {
      flex: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-name::before {
      content: '\2022';
      margin-right: 10px;
      margin-left: 6px;
      font-family: Arial, Helvetica, sans-serif;
    }

    &.selected {
      background-color: $color-lavender;
    }

    & .delete-btn {
      display: none;
    }

    &:hover {
      background-color: $color-lavender;

      & .delete-btn {
        height: 24px;
        display: flex;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-lg) {
  .property-cascader {
    width: 100%;
    flex-direction: column;

    &__scroll-container {
      width: 100%;
      height: 400px;
      flex: none;
    }
  }

  .property-tree {
    width: 100%;

    &__scroll-container {
      height: 400px;
    }
  }
}
