.super-admin-workplace {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 30px;

  &__content {
    position: relative;
    height: 100%;
    flex: 1;
  }
}
