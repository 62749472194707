.catalog-list-settings {
  padding: 40px;
  flex: 1;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    & .ant-table-row:hover {
      cursor: pointer;
    }
  }
}

.catalog-settings {
  padding: 40px;
  position: relative;
  height: 100%;
  display: flex;
  gap: 30px;
}

.catalog-settings-tree {
  width: 635px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    height: 28px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .three-dot-menu {
      display: none;
    }

    &:hover {
      & .three-dot-menu {
        display: flex;
      }
    }
  }

  &__catalog-name {
    font-family: $fontFamily-Roboto-Bold;
  }

  &__item-name {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__scroll-container {
    position: relative;
    height: 80vh;
    overflow: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  & .ant-tree {
    min-width: 100%;
    position: absolute;

    &-title {
      .root {
        font-family: $fontFamily-Roboto-Bold;
      }

      & .three-dot-menu {
        opacity: 0;
      }

      & .btn-icon {
        display: none;
      }

      &:hover {
        & .three-dot-menu {
          opacity: 1;
        }

        & .btn-icon {
          display: block;
        }
      }
    }
  }
}

.catalog-settings-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__form {
    &-item {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-lavender;

      & .ant-select {
        width: auto;
      }

      &-label {
        display: flex;
        flex-direction: column;
      }

      &-title {
        line-height: 20px;
      }

      &-description {
        font-size: $fontSize-xsm;
        color: $color-metallic-silver;
        line-height: 20px;
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &-subtitle {
      padding: 30px 16px 16px;
      font-family: $fontFamily-Roboto-Bold;
    }

    &-image {
      padding: 16px;

      & img {
        width: 73px !important;
        height: 73px !important;
        object-fit: cover;
      }
    }
  }
}

.category-template-search-modal {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .ant-tree {
      &-title {
        & .btn-icon {
          display: none;
        }

        &:hover {
          & .btn-icon {
            display: block;
          }
        }
      }
    }
  }

  &__scroll-container {
    max-height: 60vh;
    overflow: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }

  &__item-name {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.category-filter-modal {
  &__body {
    max-height: 60vh;
    overflow-y: scroll;
    scrollbar-color: $color-dark-electric-blue transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-dark-electric-blue;
      border-radius: 6px;
    }
  }
}
