.discount-list {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  &__title {
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }

  &__table {
    width: 100%;

    & .ant-table-row:hover {
      cursor: pointer;
    }

    &-item {
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
