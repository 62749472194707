.subdivisions-settings {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  &__title {
    margin-bottom: 32px;
    font-size: $fontSize-lg;
    text-transform: uppercase;
  }
}

.create-subdivision-modal {
  width: 360px !important;

  & .ant-modal-content {
    padding: 0;
  }
}

@media (max-width: $screen-maxWidth-xl) {
  .subdivisions-settings {
    flex-direction: column;
  }
}
