.specification {
  height: 100%;

  &__spin {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  &__title {
    margin-right: 30px;
    text-transform: uppercase;
    font-size: $fontSize-lg;
  }

  &__actions {
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 20px;

    &-remove-all {
      padding: 0px;
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      box-shadow: none;

      & .ant-wave {
        display: none;
      }

      &:disabled {
        background: transparent;

        & span {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    & * {
      color: $color-independence;
    }
  }

  &__id {
    margin-right: 30px;
    font-size: $fontSize-lg;
    color: rgba(0, 0, 0, 0.4);
  }

  &__date {
    margin-right: 30px;
    font-size: $fontSize-lg;
    color: rgba(0, 0, 0, 0.4);
  }

  &__close {
    display: inline-block;
  }

  &__table {
    width: 100%;

    & .ant-table-row {
      &-selected {
        & .ant-table-cell {
          background: transparent !important;
        }
      }
    }

    &-item {
      &-user {
        &-name {
          font-size: $fontSize-xsm;
          color: rgba(0, 0, 0, 0.4);
        }

        &-subdivision {
          width: 176px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-codes {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &-sellerCode {
        color: $color-metallic-silver;
      }

      &-vendorCode {
        display: flex;
        align-items: center;
        gap: 3px;
        color: $color-islamic-green;

        & .ant-btn-link span {
          color: $color-islamic-green;
          text-decoration: underline;

          &:hover {
            color: $color-majorelle-blue;
          }
        }

        &-empty {
          display: flex;
          align-items: center;
          gap: 3px;

          & .ant-btn-link span {
            color: $color-majorelle-blue;
            text-decoration: none;
          }
        }
      }

      &-name {
        max-width: 418px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &-container {
          width: 100%;
        }
      }

      &-price {
        white-space: nowrap;
      }

      &-total {
        white-space: nowrap;
      }

      &-address {
        width: 196px;
        font-size: $fontSize-xsm;
        color: rgba(0, 0, 0, 0.4);
        text-align: end;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media (max-width: $screen-maxWidth-xxl) {
  .specification {
    &__table {
      & .ant-table-cell {
        padding: 8px !important;
      }
      &-item {
        &-user {
          &-subdivision {
            width: 100px;
          }
        }

        &-name {
          max-width: 190px;
        }

        &-address {
          width: 100px;
        }
      }
    }
  }
}
