.supply-status {
  padding: 7px 10px 7px 12px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;

  &.created {
    background-color: $color-background-pale-yellow;
    color: $color-general-yellow;
  }

  &.cancelled {
    background-color: $color-background-pale-red;
    color: $color-general-red;
  }

  &.pending,
  &.delivered,
  &.delivery,
  &.placed,
  &.approved,
  &.processing {
    background-color: $color-background-pale-green;
    color: $color-general-bright-green;
  }
}
