.inventory-list {
  position: relative;
  padding: 40px 40px 0;
  height: 100%;
  display: flex;
  align-items: normal;
  flex-direction: column;

  &__filters {
    display: flex;
    gap: 12px;

    &-select {
      max-width: 280px;
    }
  }

  &__actions {
    position: sticky;
    top: 0;
    margin-top: 40px;
    padding-bottom: 12px;
    min-height: 56px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: $color-general-white;
    z-index: 1;
  }

  &__table {
    flex: 1;

    & .ant-table {
      &-row {
        & .ant-table-cell {
          vertical-align: middle;
        }
      }
    }

    &-item {
      &-name {
        &-footer {
          display: flex;
          align-items: center;
          column-gap: 20px;
          flex-wrap: wrap;
        }
      }
    }

    &-end-of-list {
      & .ant-table {
        padding-bottom: 80px;

        &-row {
          &:last-child {
            & .ant-table-cell {
              border-bottom-color: $color-general-bright-green !important;
            }
          }
        }
      }
    }

    &-empty {
      flex: 1;

      & .ant-spin {
        &-nested-loading {
          height: 100%;
        }

        &-container {
          height: 100%;
        }
      }

      & .ant-table {
        height: 100%;

        &-container {
          height: 100%;
        }

        &-content {
          height: 100%;
        }

        & table {
          height: 100%;
        }
      }
    }
  }
}

.inventory-card {
  &__info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__good-card {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    background: $color-hover-light-grey;
    border-radius: 8px;
    cursor: pointer;

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__block {
    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $color-outline-grey;
    }
  }

  &__notification {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $color-background-black;
    border-radius: 8px;
  }
}
