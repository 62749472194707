.supply-card-min {
  margin-bottom: 32px;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__container {
    padding: 12px;
    border: 1px solid $color-outline-grey;
    border-radius: 12px;
  }

  &__divider {
    margin: 16px 0;
    height: 1px;
    background-color: $color-outline-light-grey;
  }

  &__card {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    background-color: $color-hover-light-grey;
    border-radius: 8px;

    &-table {
      flex: 1;
      display: grid;
      grid-template-columns: 190px 1fr;
      gap: 20px 0;

      &-row {
        display: contents;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.create {
    & .supply-card-min {
      &__container {
        border: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='1' stroke-dasharray='12%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 12px;
      }

      &__card {
        background-color: $color-general-white;

        & .ant-image {
          padding: 1px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='12%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 8px;
        }
      }
    }
  }
}

.supply-card {
  &.nested {
    & .ant-drawer {
      &-header-title {
        justify-content: flex-start;
      }
    }
  }

  & .ant-drawer {
    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  &__block {
    margin-bottom: 72px;

    &-title {
      margin-bottom: 24px;
      padding-bottom: 12px;
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $color-outline-grey;
    }

    &-table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 0;

      &-row {
        display: contents;
      }
    }

    &-edit {
      margin-bottom: 72px;

      &-label {
        margin-bottom: 6px;
        display: inline-block;
      }

      &-icon {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__goods {
    margin-bottom: 32px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $color-hover-light-grey;
    border-radius: 8px;

    &-name {
      margin-bottom: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__need {
    margin-bottom: 132px;
    padding: 12px;
    border: 1px solid $color-outline-grey;
    border-radius: 12px;
    cursor: pointer;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-divider {
      margin: 16px 0;
      height: 1px;
      background-color: $color-outline-light-grey;
    }

    &-block {
      padding: 20px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      background-color: $color-hover-light-grey;
      border-radius: 8px;

      &-table {
        display: grid;
        grid-template-columns: 180px 1fr;
        gap: 20px 0;

        &-row {
          display: contents;
        }
      }
    }

    &-user {
      display: flex;
      align-items: flex-start;
      gap: 12px;

      &-initials {
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-background-dark-grey;
        border-radius: 6px;
      }

      &-name {
        line-height: 28px;
      }
    }
  }
}

.supply-correction-request-drawer {
  & .ant-drawer {
    &-header-title {
      justify-content: flex-start;
    }

    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  &__block {
    margin-bottom: 32px;

    &-label {
      margin-bottom: 6px;
      display: block;
    }
  }
}
